export const EMOJI_LIST = [
  {
    title: '常用',
    type: 'emoji',
    // 个别表情为组合表情, 需要把每个表情作为单独元素
    content: [
      '0️⃣',
      '1️⃣',
      '2️⃣',
      '3️⃣',
      '4️⃣',
      '5️⃣',
      '6️⃣',
      '7️⃣',
      '8️⃣',
      '9️⃣',
      '🔟',
      '➡️',
      '⬇️',
      '⬅️',
      '⬆️',
      '🙋🏻‍♂️',
      '🙋🏻‍♀️',
      '👀',
      '➕',
      '➖',
      '✔️',
      '✖️',
      '⭕',
      '❌',
      '‼',
      '⁉',
      '❗',
      '❓',
      '💰',
      '🎁',
      '🚗',
      '🍠',
      '🉐',
      '🈷',
      '🉑',
      '✅',
      '🈶',
      '🆚',
      '🆗',
      '🆕',
      '🆓',
      '💭',
      '💬',
      '✉',
      '💌',
      '📩',
      '📨',
      '☎️',
      '📞',
      '🔋',
      '⚡',
      '⛽',
      '🗣️',
      '📅',
      '😎',
      '🤔',
      '😅',
      '😳',
      '👌',
      '👆',
      '👇',
      '👈',
      '👉',
      '☝',
      '✊',
      '🫰',
      '✍',
      '🔥',
      '💥',
      '✨',
      '⭐',
      '🌟',
      '❤',
      '🚫',
      '🔊',
      '📣',
      '💯',
      '🔆',
      '🌿',
      '🔍',
      '🌈',
      '🌝',
      '❄',
      '💦',
      '💧',
      '🔁',
    ],
  },
  {
    title: '基础',
    type: 'emoji',
    content:
      '😀😃😄😁😆😅🤣😂🙂🙃🫠😉😊😇🥰😍🤩😘😗😚😙🥲😋😛😜🤪😝🤑🤗🤭🫢🫣🤫🤔🫡🤐🤨😐😑😶🫥😶‍🌫️😏😒🙄😬😮‍💨🤥😌😔😪🤤😴😷🤒🤕🤢🤮🤧🥵🥶🥴😵😵‍💫🤯🤠🥳🥸😎🤓🧐😕🫤😟🙁😮😯😲😳🥺🥹😦😧😨😰😥😢😭😱😖😣😞😓😩😫🥱😤😡😠🤬😈👿💀☠️💩🤡👹👺👻👽👾🤖😺😸😹😻😼😽🙀😿😾🙈🙉🙊💋💌💘💝💖💗💓💞💕💟❣️💔❤️‍🔥❤️‍🩹❤️🧡💛💚💙💜🤎🖤🤍💯💢💥💫💦💨🕳️💣💬👁️‍🗨️🗨️🗯️💭💤♈♉♊♋♌♍♎♏♐♑♒♓',
  },
  {
    title: '手势',
    type: 'emoji',
    content:
      '👋🤚🖐️✋🖖🫱🫲🫳🫴👌🤌🤏✌️🤞🫰🤟🤘🤙👈👉👆🖕👇☝️🫵👍👎✊👊🤛🤜👏🙌🫶👐🤲🤝🙏✍️💅🤳💪🦾🦿🦵🦶👂🦻👃🧠🫀🫁🦷🦴👀👁️👅👄🫦👹👺💀👻👽👦👧👨👩👴👵👶👱👮👲👳👷👸💂🎅👰👼💆💇🙍🙎🙅🙆💁🙋🙇🙌🙏👤👥🚶🏃👯💃👫👬👭💏💑👪',
  },
  {
    title: '旅行',
    type: 'emoji',
    content:
      '🚂🚃🚄🚅🚆🚇🚈🚉🚊🚝🚞🚋🚌🚍🚎🚐🚑🚒🚓🚔🚕🚖🚗🚘🚙🛻🚚🚛🚜🏎🏍🛵🦽🦼🛺🚲🛴🌍🌎🌏🌐🗺🗾🧭🏔⛰🌋🗻🏕🏖🏜🏝🏞🏟🏛🏗🧱🪨🪵🛖🏘🏚🏠🏡🏢🏣🏤🏥🏦🏨🏩🏪🏫🏬🏭🏯🏰💒🗼🗽⛪🕌🛕🕍⛩🕋⛲⛺🌁🌃🏙🌄🌅🌆🌇🌉♨🎠🛝🎡🎢💈🎪🛹🛼🚏🛣🛤🛢⛽🛞🚨🚥🚦🛑🚧⚓🛟⛵🛶🚤🛳⛴🛥🚢✈🛩🛫🛬🪂💺🚁🚟🚠🚡🛰🚀🛸🛎🧳⌛⏳⌚⏰⏱⏲🕰🕛🕧🕐🕜🕑🕝🕒🕞🕓🕟🕔🕠🕕🕡🕖🕢🕗🕣🕘🕤🕙🕥🕚🕦🌑🌒🌓🌔🌕🌖🌗🌘🌙🌚🌛🌜🌡☀🌝🌞🪐⭐🌟🌠🌌☁⛅⛈🌤🌥🌦🌧🌨🌩🌪🌫🌬🌀🌈🌂☂☔⛱⚡❄☃⛄☄🔥💧🌊',
  },
  {
    title: '食物',
    type: 'emoji',
    content:
      '🍇🍈🍉🍊🍋🍌🍍🥭🍎🍏🍐🍑🍒🍓🫐🥝🍅🫒🥥🥑🍆🥔🥕🌽🌶🫑🥒🥬🥦🧄🧅🍄🥜🫘🌰🍞🥐🥖🫓🥨🥯🥞🧇🧀🍖🍗🥩🥓🍔🍟🍕🌭🥪🌮🌯🫔🥙🧆🥚🍳🥘🍲🫕🥣🥗🍿🧈🧂🥫🍱🍘🍙🍚🍛🍜🍝🍠🍢🍣🍤🍥🥮🍡🥟🥠🥡🦀🦞🦐🦑🦪🍦🍧🍨🍩🍪🎂🍰🧁🥧🍫🍬🍭🍮🍯🍼🥛☕🫖🍵🍶🍾🍷🍸🍹🍺🍻🥂🥃🫗🥤🧋🧃🧉🧊🥢🍽🍴🥄🔪🫙🏺',
  },
  {
    title: '物体',
    type: 'emoji',
    content:
      '👓🕶🥽🥼🦺👔👕👖🧣🧤🧥🧦👗👘🥻🩱🩲🩳👙👚👛👜👝🛍🎒🩴👞👟🥾🥿👠👡🩰👢👑👒🎩🎓🧢🪖⛑📿💄💍💎🔇🔈🔉🔊📢📣📯🔔🔕🎼🎵🎶🎙🎚🎛🎤🎧📻🎷🪗🎸🎹🎺🎻🪕🥁🪘📱📲☎📞📟📠🔋🪫🔌💻🖥🖨⌨🖱🖲💽💾💿📀🧮🎥🎞📽🎬📺📷📸📹📼🔍🔎🕯💡🔦🏮🪔📔📕📖📗📘📙📚📓📒📃📜📄📰🗞📑🔖🏷💰🪙💴💵💶💷💸💳🧾💹✉📧📨📩📤📥📦📫📪📬📭📮🗳✏✒🖋🖊🖌🖍📝💼📁📂🗂📅📆🗒🗓📇📈📉📊📋📌📍📎🖇📏📐✂🗃🗄🗑🔒🔓🔏🔐🔑🗝🔨🪓⛏⚒🛠🗡⚔🔫🪃🏹🛡🪚🔧🪛🔩⚙🗜⚖🦯🔗⛓🪝🧰🧲🪜⚗🧪🧫🧬🔬🔭📡💉🩸💊🩹🩼🩺🩻🚪🛗🪞🪟🛏🛋🪑🚽🪠🚿🛁🪤🪒🧴🧷🧹🧺🧻🪣🧼🫧🪥🧽🧯🛒🚬⚰🪦⚱🗿🪧🪪',
  },
  {
    title: '活动',
    type: 'emoji',
    content:
      '🎃🎄🎆🎇🧨✨🎈🎉🎊🎋🎍🎎🎏🎐🎑🧧🎀🎁🎗🎟🎫🎖🏆🏅🥇🥈🥉⚽⚾🥎🏀🏐🏈🏉🎾🥏🎳🏏🏑🏒🥍🏓🏸🥊🥋🥅⛳⛸🎣🤿🎽🎿🛷🥌🎯🪀🪁🎱🔮🪄🧿🪬🎮🕹🎰🎲🧩🧸🪅🪩🪆♠♥♦♣♟🃏🀄🎴🎭🖼🎨🧵🪡🧶🪢',
  },
  {
    title: '自然',
    type: 'emoji',
    content:
      '🙈🙉🙊🐵🐒🦍🦧🐶🐕🦮🐕🐩🐺🦊🦝🐱🐈🐈🦁🐯🐅🐆🐴🐎🦄🦓🦌🦬🐮🐂🐃🐄🐷🐖🐗🐽🐏🐑🐐🐪🐫🦙🦒🐘🦣🦏🦛🐭🐁🐀🐹🐰🐇🐿🦫🦔🦇🐻🐻🐨🐼🦥🦦🦨🦘🦡🐾🦃🐔🐓🐣🐤🐥🐦🐧🕊🦅🦆🦢🦉🦤🪶🦩🦚🦜🐸🐊🐢🦎🐍🐲🐉🦕🦖🐳🐋🐬🦭🐟🐠🐡🦈🐙🐚🪸🐌🦋🐛🐜🐝🪲🐞🦗🪳🕷🕸🦂🦟🪰🪱🦠💐🌸💮🪷🏵🌹🥀🌺🌻🌼🌷🌱🪴🌲🌳🌴🌵🌾🌿☘🍀🍁🍂🍃🪹🪺',
  },
];

export const WORD_COUNT_LIST = [
  {
    value: 150,
    text: '150字内',
  },
  {
    value: 300,
    text: '250~300字',
  },
  {
    value: 600,
    text: '500~600字',
  },
];

// export const STORY_TYPE = {
//   substance: "汽车干货",
//   kol: "车主种草",
//   pickup: "车主提车",
//   other: "经销商种草",
// };
export const STORY_TYPE_MAP = [
  {
    value: 'kol',
    label: '车主种草',
  },
  {
    value: 'pickup',
    label: '车主提车',
  },
  {
    value: 'other',
    label: '经销商种草',
  },
];
export const TOPIC_LIST = [
  {
    value: 1,
    label: '种草',
    isChoose: true,
  },
  {
    value: 2,
    label: '干货',
    isChoose: false,
  },
  // {
  //   value:3,
  //   label:"金融政策",
  //   isChoose:false
  // }
];
export const CLASSIFY_LIST = [
  {
    value: 1,
    label: '买车选车',
  },
  {
    value: 2,
    label: '保养/用车技巧',
  },
  {
    value: 3,
    label: '安全驾驶技巧',
  },
];
export const DESCRIPTION_LIST = [
  {
    value: 'SIMPLE',
    label: '简略',
    isChoose: true,
  },
  {
    value: 'DETAILED',
    label: '详细',
    isChoose: false,
  },
];
