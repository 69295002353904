<template>
  <div class="note-container">
    <div class="container-top">
      <div class="top">
        <div style="font-weight: bold"></div>
        <div class="btn-line" v-show="!readOnly">
          <a-space style="position: relative">
            <a-button :loading="btnLoading" @click="addEmoji">+表情</a-button>
            <span>
              <a-button :loading="btnLoading" @click="addTitle">+标题</a-button>
              <a-card
                id="tags"
                style="width: 360px; min-height: 100px; position: absolute; right: 0; z-index: 3"
                v-show="showAiTitle"
              >
                <div style="margin-bottom: 15px">
                  <div :style="{ borderBottom: '1px solid #E9E9E9', marginBottom: '10px' }">选择想要的标题</div>
                  <a-radio-group v-model="titleByAi" @change="() => {}">
                    <div style="display: flex; flex-direction: column">
                      <a-radio v-for="(val, index) in aiTitleList" :value="val" :key="index" style="margin-bottom: 5px">
                        {{ index + 1 }}. {{ val }}
                      </a-radio>
                    </div>
                  </a-radio-group>
                </div>
                <div style="display: flex; justify-content: flex-end">
                  <a-space>
                    <a-button @click="handleTitleClose">关闭</a-button>
                    <a-button @click="confirmTitle">确认</a-button>
                  </a-space>
                </div>
              </a-card>
            </span>
            <span>
              <a-button :loading="btnLoading" @click="handleTagEdit">+话题</a-button>
              <a-card
                id="tags"
                style="width: 360px; min-height: 100px; position: absolute; z-index: 3"
                v-show="showCheckTag"
              >
                <template>
                  <div style="margin-bottom: 15px">
                    <div :style="{ borderBottom: '1px solid #E9E9E9', marginBottom: '10px' }">
                      <div style="margin-bottom: 10px">选择想要加入到笔记中的话题</div>
                      <a-checkbox :indeterminate="indeterminate" :checked="checkAllTag" @change="onCheckAllTag">
                        全选
                      </a-checkbox>
                    </div>
                    <a-checkbox-group v-model="tagCheckedList" :options="tagOptionList" @change="onTagChange">
                    </a-checkbox-group>
                  </div>
                  <div style="display: flex; justify-content: flex-end">
                    <a-space>
                      <a-button @click="handleTagClose">关闭</a-button>
                      <a-button @click="confirmAddTag">确认</a-button>
                    </a-space>
                  </div>
                </template>
              </a-card>
            </span>
            <a-dropdown :trigger="['click']" v-if="rewriteTypeList.length">
              <a-button :loading="btnLoading" @click="() => {}">全文AI改写</a-button>
              <a-menu slot="overlay" @click="(e) => onWeekContextMenuClick(e)">
                <a-menu-item v-for="types in rewriteTypeList" :key="types.id">
                  <a-button type="link">{{ types.aiRewriteName }}</a-button>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <a-button :loading="btnLoading" @click="automaticLineWrapping">自动分行</a-button>
            <a-button :loading="btnLoading" @click="handleCheckSenseWord">违规检测</a-button>
          </a-space>
          <!-- <a-button class="ml-5" :loading="btnLoading" type="primary" @click="handleSave">保存</a-button> -->
          <!-- <a-button class="ml-5" :loading="btnLoading" type="primary" @click="handlePublish">保存并发布</a-button> -->
        </div>
      </div>
      <a-alert
        v-if="isNoteCheckError"
        message="文案中存在高风险词，已高亮处理，请修改"
        banner
        closable
        @close="closeCheckError"
      />
      <a-alert
        v-if="isNoteCheckRepeat"
        message="文案发布前需做对内容检查修改，避免被平台判断为同质化内容，已高亮处理"
        type="info"
        closable
        @close="closeRepeat"
      />
      <div style="position: relative">
        <div>
          <a-textarea
            :disabled="readOnly"
            class="input-class"
            placeholder="笔记标题"
            :auto-size="{ maxRows: 3 }"
            v-model="noteTitle"
          ></a-textarea>
          <!-- <a-input placeholder="笔记标题" style="margin: 5px 0" v-model="noteTitle" allowClear></a-input> -->
        </div>
        <div
          style="margin-left: 10px; color: #999999"
          :class="['word-count', { red: noteTitle && noteTitle.length > 20 }]"
        >
          {{ noteTitle ? noteTitle.length : 0 }} / 20
          <a-icon v-show="noteTitle && noteTitle.length > 20" type="exclamation" />
        </div>
      </div>
      <div id="wangeditor" style="position: relative">
        <div ref="editorElem" class="editor-style"></div>
        <a-card id="floating" v-show="showAiBtn" :style="`${aiTextAfter ? 'width: 560px' : null}`">
          <a-button v-show="!aiLoading && !aiTextAfter" @click="handleWriteAi">Ai修改措辞</a-button>
          <div v-show="aiLoading"><a-icon type="loading" style="margin-right: 10px" />正在改写</div>
          <div v-show="aiTextAfter && !aiLoading">
            <div style="margin-bottom: 10px">{{ aiTextAfter }}</div>
            <a-space
              ><a-button @click="handleRewriteAi">重新改写</a-button>
              <a-button @click="handleReplaceAi">替换原文案</a-button>
            </a-space>
          </div>
        </a-card>
      </div>
      <div class="bottom">
        <div style="display: flex; color: #999999">
          <a-popover placement="topRight">
            <template slot="content">
              <p>小红书对特殊符号的计数较混乱，此处超过900字后，</p>
              <p>在小红书发布时需检测笔记是否完整。</p>
            </template>
            <div style="cursor: pointer"><a-icon type="question-circle" style="margin-right: 5px" />小红书计数规则</div>
          </a-popover>
          <span style="margin: 0 5px" :class="[{ red: wordCount > 900 }]">{{ wordCount || 0 }}/1000</span>
        </div>
      </div>
      <div class="tag-line">
        <div style="width: 50px">话题：</div>
        <div style="flex: 1">
          <a-tag
            v-for="tag in tagList"
            :key="tag.id"
            style="margin-bottom: 5px; user-select: none"
            color="blue"
            :closable="readOnly ? false : true"
            @close="() => handleClose(tag.id)"
          >
            <span v-show="tag.isHot">🔥</span>{{ tag.name }}
          </a-tag>
          <a-tag v-if="!showHashTag && !readOnly" @click="handleTagEdit"> <a-icon type="plus" /> 话题</a-tag>
          <div style="display: flex; align-items: center" v-if="showHashTag">
            <a-select
              v-model="newHashTag"
              placeholder="请输入话题搜索"
              style="width: 380px"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              show-search
              @search="handleHashTagSearch"
              @change="handleHashTagChange"
              @blur="handleHashTagBlur"
            >
              <a-select-option v-for="value in searchTagList" :key="value.id" :value="value.id">
                <div style="display: flex; justify-content: space-between">
                  <div>{{ value.name }}</div>
                  <div style="color: #bfc3c9; font-size: 12px">{{ common.numEdit(value.viewNum) }}次浏览</div>
                </div>
              </a-select-option>
            </a-select>
            <a-icon type="loading" style="margin-left: 10px; color: #0000ff69" v-show="hashTagLoading" />
            <div style="margin-left: 10px" v-show="showTip">无匹配结果，请修改重试或前往小红书App新建该话题</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 话题抽屉 -->
    <a-drawer
      title="添加话题"
      :width="600"
      :visible="showTagModal"
      :body-style="{ paddingBottom: '0' }"
      @close="showTagModal = false"
    >
      <div>
        <div>
          <div>已添加话题</div>
          <div class="tag-line">
            <div style="width: 50px">话题：</div>
            <a-button v-show="!tagList.length && localTagList.length" type="dashed" @click="useLocalTag"
              >使用上次推送/发布的笔记话题</a-button
            >
            <div style="flex: 1">
              <a-tag
                v-for="tag in tagList"
                :key="tag.id"
                style="margin-bottom: 5px; user-select: none"
                color="blue"
                :closable="readOnly ? false : true"
                @close="() => handleClose(tag.id)"
              >
                <span v-show="tag.isHot">🔥</span>{{ tag.name }}
              </a-tag>
            </div>
          </div>
        </div>

        <div style="margin: 15px 0">
          <div style="margin-bottom: 15px; display: flex">
            <a-input-search
              v-model.trim="tagKeyword"
              style="width: 80%; margin-right: 15px"
              placeholder="输入关键字后按回车搜索话题"
              :loading="localLoading || xhsLoading"
              @search="handleTagSearch"
            ></a-input-search>
            <div>
              <template v-if="!noteTitle || !noteString">
                <a-tooltip placement="topLeft">
                  <template slot="title" v-if="!noteTitle || !noteString"> 笔记标题、正文不可为空 </template>
                  <template slot="title" v-else>
                    {{ xhsDisabledTips }}
                  </template>
                  <a-button disabled>AI推荐</a-button>
                </a-tooltip>
              </template>
              <template v-else>
                <a-button :loading="btnLoading" @click="addHashTag">AI推荐</a-button>
              </template>
              <a-card
                id="tags"
                style="width: 360px; min-height: 100px; position: absolute; right: 0px; z-index: 3"
                v-show="showCheckTag"
              >
                <template>
                  <div style="margin-bottom: 15px">
                    <div :style="{ borderBottom: '1px solid #E9E9E9', marginBottom: '10px' }">
                      <div style="margin-bottom: 10px">选择想要加入到笔记中的话题</div>
                      <a-checkbox :indeterminate="indeterminate" :checked="checkAllTag" @change="onCheckAllTag">
                        全选
                      </a-checkbox>
                    </div>
                    <a-checkbox-group v-model="tagCheckedList" :options="tagOptionList" @change="onTagChange">
                    </a-checkbox-group>
                  </div>
                  <div style="display: flex; justify-content: flex-end">
                    <a-space>
                      <a-button @click="handleTagClose">关闭</a-button>
                      <a-button @click="confirmAddTag">确认</a-button>
                    </a-space>
                  </div>
                </template>
              </a-card>
            </div>
          </div>
          <div style="display: flex; align-items: center; justify-content: space-between">
            <div>
              <a-checkbox style="margin-right: 15px" v-model="localCheck" disabled> 本地搜索 </a-checkbox>
              <template v-if="xhsDisabled">
                <a-tooltip placement="top">
                  <template slot="title">
                    {{ xhsDisabledTips }}
                  </template>
                  <a-checkbox style="margin-right: 15px" v-model="xhsCheck" :disabled="xhsDisabled">
                    小红书搜索
                  </a-checkbox>
                </a-tooltip>
              </template>
              <template v-else>
                <a-checkbox style="margin-right: 15px" v-model="xhsCheck" :disabled="xhsDisabled">
                  小红书搜索
                </a-checkbox>
              </template>
              <a-tooltip placement="bottom" :overlayStyle="{ 'min-width': '360px' }" trigger="click">
                <template slot="title">
                  <div style="margin-bottom: 8px">小红书搜索需要授权以下任意小红书号</div>
                  <div class="media-list">
                    <a-spin :spinning="mediaLoading">
                      <div class="media-item" v-for="(val, ind) in mediaList" :key="ind">
                        <div class="media-left">
                          <div class="left-left" v-show="val.avatar">
                            <img :src="val.avatar" alt="头像" referrerpolicy="no-referrer" />
                          </div>
                          <div class="left-right">
                            <div style="margin-left: 15px">{{ val.nickname }}</div>
                            <div class="line-flex right-txt">
                              <div class="logo" v-show="val.code">
                                <img src="@/assets/icon/xhs_logo.png" alt="" />
                              </div>
                              <div>{{ val.code || '-' }}</div>
                            </div>
                          </div>
                        </div>
                        <div class="media-right">
                          <a-tooltip>
                            <template slot="title">
                              <div>授权时间：{{ val.p1OauthTime || '-' }}</div>
                            </template>
                            <div class="sync-succ" v-show="val.p1OauthStatus === 1">
                              笔记数据
                              <a-icon style="margin-left: 5px" type="check-circle" />
                            </div>
                          </a-tooltip>
                          <a-tooltip>
                            <template slot="title">
                              <div>失效时间：{{ val.p1OauthExpireTime || '-' }}</div>
                            </template>
                            <div class="sync-err" v-show="val.p1OauthStatus !== 1">
                              笔记数据<a-icon style="margin-left: 5px" type="exclamation-circle" />
                            </div>
                          </a-tooltip>
                        </div>
                      </div>
                    </a-spin>
                  </div>
                </template>
                <a-button type="link" @click="updateMediaList">查看授权</a-button>
              </a-tooltip>
            </div>
            <a-tooltip placement="top">
              <template slot="title">
                <div>本地搜索：历史搜索过的话题</div>
                <div>小红书搜索：实时从小红书获取话题信息，需要保持帐号授权有效</div>
              </template>
              <div style="cursor: pointer">
                <a-icon type="question-circle" style="margin-right: 10px" />数据说明
              </div></a-tooltip
            >
          </div>
        </div>

        <div v-show="showTagSearch">
          <div>
            <div class="tag_title">本地搜索</div>
            <a-spin :spinning="localLoading">
              <div class="tag_list">
                <div
                  :class="['tag_item', { check_item: isInTagList(item.id) != -1 }]"
                  v-for="(item, index) in localRes"
                  :key="index"
                  @click="handleTagClick(item)"
                >
                  <span>
                    <span v-show="item.isHot">🔥</span>
                    {{ item.name }}
                  </span>
                  <span>
                    <a-icon
                      type="check-circle"
                      theme="twoTone"
                      two-tone-color="#52c41a"
                      v-show="isInTagList(item.id) != -1"
                    />
                  </span>
                </div>
                <div class="tips_line" v-show="localResTips">{{ localResTips }}</div>
              </div>
            </a-spin>
          </div>
          <div v-show="xhsCheck">
            <div class="tag_title">小红书搜索</div>
            <a-spin :spinning="xhsLoading">
              <div class="tag_list">
                <div
                  :class="['tag_item', { check_item: isInTagList(item.id) != -1 }]"
                  style="width: 95%"
                  v-for="(item, index) in xhsRes"
                  :key="index"
                  @click="handleTagClick(item)"
                >
                  <span><span v-show="item.isHot">🔥</span>{{ item.name }}</span>
                  <span
                    ><span style="margin-right: 10px">{{ common.numEdit(item.viewNum) }}次浏览</span
                    ><a-icon
                      type="check-circle"
                      theme="twoTone"
                      two-tone-color="#52c41a"
                      v-show="isInTagList(item.id) != -1"
                  /></span>
                </div>
                <div class="tips_line" v-show="xhsResTipsType">
                  <template v-if="xhsResTipsType === 1">
                    <div>无匹配结果，请修改重试或前往小红书App新建该话题</div>
                  </template>
                  <template v-else-if="xhsResTipsType === 2">
                    <div>
                      需先更新授权，
                      <a-tooltip placement="top" :overlayStyle="{ 'min-width': '360px' }" trigger="click">
                        <template slot="title">
                          <div style="margin-bottom: 8px">小红书搜索需要授权以下任意小红书号</div>
                          <div class="media-list">
                            <a-spin :spinning="mediaLoading">
                              <div class="media-item" v-for="(val, ind) in mediaList" :key="ind">
                                <div class="media-left">
                                  <div class="left-left" v-show="val.avatar">
                                    <img :src="val.avatar" alt="头像" referrerpolicy="no-referrer" />
                                  </div>
                                  <div class="left-right">
                                    <div style="margin-left: 15px">{{ val.nickname }}</div>
                                    <div class="line-flex right-txt">
                                      <div class="logo" v-show="val.code">
                                        <img src="@/assets/icon/xhs_logo.png" alt="" />
                                      </div>
                                      <div>{{ val.code || '-' }}</div>
                                    </div>
                                  </div>
                                </div>
                                <div class="media-right">
                                  <a-tooltip>
                                    <template slot="title">
                                      <div>授权时间：{{ val.p1OauthTime || '-' }}</div>
                                      <div>失效时间：{{ val.p1OauthExpireTime || '-' }}</div>
                                    </template>
                                    <div class="sync-succ" v-show="val.p1OauthStatus === 1">
                                      笔记数据
                                      <a-icon style="margin-left: 5px" type="check-circle" />
                                    </div>
                                  </a-tooltip>
                                  <a-tooltip>
                                    <template slot="title">
                                      <div>失效时间：{{ val.p1OauthExpireTime || '-' }}</div>
                                    </template>
                                    <div class="sync-err" v-show="val.p1OauthStatus !== 1">
                                      笔记数据<a-icon style="margin-left: 5px" type="exclamation-circle" />
                                    </div>
                                  </a-tooltip>
                                </div>
                              </div>
                            </a-spin>
                          </div>
                        </template>
                        <a @click.stop="updateMediaList">点击查看授权。</a>
                      </a-tooltip>
                    </div>
                  </template>
                </div>
              </div>
            </a-spin>
          </div>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { EMOJI_LIST } from './constant';
import E from 'wangeditor';
import api from '@/api/xhsAgencyApi';
import { cloneDeep, debounce } from 'lodash';
import { computePosition, shift, flip, offset } from '@floating-ui/dom';
import { trackRequest } from '@/utils/track';
import { handleNoteCheck } from '@/pages/app/xhsAgency/utils.js';

export default {
  props: {
    readOnly: {
      default: false,
    },
    rewriteTypeList: {
      default: () => [],
    },
  },
  data() {
    return {
      editor: null,
      noteString: '',
      noteContent: '',
      noteTitle: '',
      btnLoading: false,
      tagList: [],
      newHashTag: undefined,
      searchTagList: [],
      hashTagLoading: false,
      showHashTag: false,
      showTip: false,
      wordCount: 0,
      isNoteCheckError: false,
      isNoteCheckRepeat: false,
      editorId: 'defaultId',
      showAiBtn: false,
      aiLoading: false,
      aiTextBefore: '',
      aiTextAfter: '',
      showCheckTag: false,
      indeterminate: false,
      checkAllTag: false,
      tagAllList: [],
      tagCheckedList: [],
      tagOptionList: [],
      showAiTitle: false,
      titleByAi: null,
      aiTitleList: [],
      // 话题抽屉相关
      showTagModal: false,
      mediaList: [],
      localCheck: true,
      xhsCheck: false,
      xhsDisabled: false,
      xhsDisabledTips: '',
      localRes: [],
      xhsRes: [],
      tagKeyword: undefined,
      localLoading: false,
      xhsLoading: false,
      showTagSearch: false,
      localResTips: undefined,
      xhsResTipsType: undefined, // 1-无数据 2-无授权
      mediaLoading: false,
    };
  },
  mounted() {
    this.initEditor();
    this.getAuthorMediaList();
  },
  beforeDestroy() {
    const textareaDom = document.getElementById('wangeditor');
    textareaDom && textareaDom.removeEventListener('mouseup', this.handleSelection);
  },
  watch: {
    readOnly: {
      handler: function (val) {
        val && this.editor.disable();
        !val && this.editor.enable();
      },
      deep: true,
    },
    noteTitle(value) {
      this.$emit('titleChange', value);
    },
    tagList: {
      handler: function (value) {
        this.$emit('tagListChange', value);
      },
      deep: true,
    },
  },
  computed: {
    localTagList() {
      const arr = localStorage.getItem('localTagList') || undefined;
      let list = [];
      if (arr) {
        list = arr.length ? JSON.parse(arr) : [];
      }
      return list;
    },
  },
  methods: {
    addEmoji() {
      this.handleTrack('add_emoji');
      this.showCheckTag = false;
      this.showAiTitle = false;
      this.btnLoading = true;
      this.editor.disable();
      this.handleRequest(
        'getEmojiContent',
        (data) => {
          this.noteContent = data.note.replace(/\n/g, '<br />');
          this.editor.txt.html(this.noteContent);
        },
        {
          note: this.noteString,
        }
      ).finally(() => {
        this.btnLoading = false;
        this.editor.enable();
      });
    },
    addTitle() {
      this.handleTrack('add_tittle');
      this.showCheckTag = false;
      this.showAiTitle = false;
      if (!this.noteString) return this.$message.info('请先输入文案');
      this.btnLoading = true;
      this.editor.disable();
      this.handleRequest(
        'getContentTitle',
        (data) => {
          this.aiTitleList = data;
          if (data.length) {
            this.showAiTitle = true;
          } else {
            this.$message.info('请重试');
          }
        },
        {
          note: this.noteString,
        }
      ).finally(() => {
        this.btnLoading = false;
        this.editor.enable();
      });
    },
    confirmTitle() {
      this.handleTrack('add_text_tag');
      if (this.titleByAi?.trim()) {
        this.noteTitle = this.titleByAi.trim();
        this.$emit('titleChange', this.titleByAi);
      }
      this.handleTitleClose();
    },
    handleTitleClose() {
      this.titleByAi = null;
      this.aiTitleList = [];
      this.showAiTitle = false;
    },
    addHashTag() {
      this.showCheckTag = false;
      if (!this.noteTitle) return this.$message.error('请输入文案标题');
      if (!this.noteString) return this.$message.error('请输入文案内容');
      const params = {
        noteDetail: this.noteString,
        noteTitle: this.noteTitle,
      };
      this.btnLoading = true;
      this.editor.disable();
      this.handleRequest(
        'getHashTagContent',
        (data) => {
          this.tagAllList = data;
          this.tagCheckedList = data.filter((val) => val.isHot).map((val) => val.id);
          if (this.tagCheckedList.length > 0 && this.tagCheckedList.length < data.length) {
            this.indeterminate = true;
            this.checkAllTag = false;
          }
          if (this.tagCheckedList.length == data.length) {
            this.indeterminate = false;
            this.checkAllTag = true;
          }
          this.tagOptionList = data.map((val) => {
            return {
              value: val.id,
              label: val.isHot ? `🔥${val.name}` : val.name,
            };
          });
          this.showCheckTag = true;
        },
        params
      ).finally(() => {
        this.btnLoading = false;
        this.editor.enable();
      });
    },
    onTagChange(tagCheckedList) {
      this.indeterminate = !!tagCheckedList.length && tagCheckedList.length < this.tagOptionList.length;
      this.checkAllTag = tagCheckedList.length === this.tagOptionList.length;
    },
    onCheckAllTag(e) {
      Object.assign(this, {
        tagCheckedList: e.target.checked ? this.tagOptionList.map((val) => val.value) : [],
        indeterminate: false,
        checkAllTag: e.target.checked,
      });
    },
    confirmAddTag() {
      const oldList = this.tagList.map((val) => val.id);
      let chooseList = [];
      this.tagAllList.forEach((item) => {
        this.tagCheckedList.forEach((value) => {
          if (item.id == value) {
            chooseList.push(item);
          }
        });
      });
      const newList = chooseList.filter((val) => oldList.indexOf(val.id) === -1);
      this.tagList.push(...newList);
      this.handleTagClose();
    },
    handleTagClose() {
      this.tagAllList = [];
      this.tagCheckedList = [];
      this.tagOptionList = [];
      this.indeterminate = false;
      this.checkAllTag = false;
      this.showCheckTag = false;
    },
    automaticLineWrapping() {
      this.handleTrack('aotu_split');
      this.showCheckTag = false;
      this.showAiTitle = false;
      this.btnLoading = true;
      this.editor.disable();
      this.handleRequest(
        'automaticLineWrapping',
        (data) => {
          this.noteContent = data.note.replace(/\n/g, '<br />');
          this.editor.txt.html(this.noteContent);
        },
        {
          note: this.noteString,
        }
      ).finally(() => {
        this.btnLoading = false;
        this.editor.enable();
      });
    },
    handleClose(id) {
      const list = cloneDeep(this.tagList);
      const tags = list.filter((tag) => tag.id !== id);
      this.tagList = tags;
    },
    getInnerText: function (t) {
      // 会读取到按钮文本 废弃
      if (!t) return '';
      var e = null == t ? void 0 : t.innerHTML;

      e = e
        .replace(/<img([^>]+)data-set="x-x-/g, '')
        .replace(/-x-x([^>]*)>/g, '')
        .replace(/<br>/g, '<硬换行>')
        .replace(/`<br>`/g, '<硬换行>')
        .replace(/<(\w+)([^>]*)class="data-clipboard-text-hide"([^>]*)>#<\/\1>/gi, '#')
        .replace(/<(\w+)(\s)class="content-hide([^>]*)>(.*?)<\/\1>/gi, '[话题]#')
        .replace(/<(\w+)([^>]*)class="data-clipboard-text-hide"([^>]*)>\[时刻\]#<\/\1>/gi, '[时刻]#');
      var n = document.createElement('div');
      (n.innerHTML = e), document.body.append(n);
      var r = n.innerText.replace(/<硬换行>/g, '\t\n');
      return document.body.removeChild(n), r;
    },
    getHtmlText(innerHTML) {
      if (!innerHTML) return innerHTML;
      // 处理标签样式 把块级元素改为p
      let result = innerHTML
        .replace(
          /<\s*(div|p|h[1-6]|section|article|header|footer|aside|main|nav|blockquote|ul|ol|li|dl|dt|dd|figure|figcaption|pre|table|tr|td|th|form|fieldset|legend|hr|address)[^>]*>/gi,
          '<p>'
        )
        .replace(
          /<\/\s*(div|p|h[1-6]|section|article|header|footer|aside|main|nav|blockquote|ul|ol|li|dl|dt|dd|figure|figcaption|pre|table|tr|td|th|form|fieldset|legend|hr|address)[^>]*>/gi,
          '<\/p>'
        )
        .replace(/<span[^>]*>/g, '<span>')
        .replace(/<br\/?><\/p>/g, '<\/p>'); // 处理标签后面多余换行符

      // 处理innerText块级元素之间会自动加的换行
      const regex = /<p>(.*?)<\/p>/gs;
      const pList = result.match(regex);
      if (pList && pList.length) result = pList.map((match) => match.replace(/<\/?p>/g, '')).join('<br/>');

      const text = result
        .replace(/<img([^>]+)data-set="x-x-/g, '')
        .replace(/-x-x([^>]*)>/g, '')
        .replace(/<br[^>]*>/g, '<硬换行>')
        .replace(/<br\/>/g, '<硬换行>')
        .replace(/<br>/g, '<硬换行>')
        .replace(/`<br>`/g, '<硬换行>')
        .replace(/<(\w+)([^>]*)class="data-clipboard-text-hide"([^>]*)>#<\/\1>/gi, '#')
        .replace(/<(\w+)(\s)class="content-hide([^>]*)>(.*?)<\/\1>/gi, '[话题]#')
        .replace(/<(\w+)([^>]*)class="data-clipboard-text-hide"([^>]*)>\[时刻\]#<\/\1>/gi, '[时刻]#');
      var n = document.createElement('div');
      (n.innerHTML = text), document.body.append(n);
      var r = n.innerText.replace(/<硬换行>/g, '\t\n');
      console.log('result ->', r);
      return document.body.removeChild(n), r;
    },
    initEditor() {
      if (this.$refs.editorElem) this.editor = new E(this.$refs.editorElem);
      this.editor.id = this.editorId;
      this.editor.config.focus = false;
      this.editor.config.placeholder = '';
      this.editor.config.zIndex = 1;
      this.editor.config.emotions = [];
      this.editor.config.showFullScreen = false;
      this.editor.config.getPasteHtmlText = true;
      this.editor.config.pasteFilterStyle = true;
      this.editor.config.pasteIgnoreImg = true;
      this.editor.config.menus = ['emoticon', 'undo', 'redo'];
      // 初始化自定义表情
      EMOJI_LIST.forEach((item) => {
        const { title, type, content } = item;
        this.editor.config.emotions.push({
          title,
          type,
          content: [...content],
        });
      });
      this.editor.create();
      this.editor.txt.html(this.noteContent);

      this.editor.config.onchange = (newHtml) => {
        this.noteContent = newHtml;
        this.noteString = newHtml ? this.getHtmlText(newHtml) : '';
        this.wordCount = this.editor.txt.text().replace(/&nbsp;/gi, ' ')?.length;
        this.$emit('editorChange', this.noteString, this.noteContent);
        this.handleCloseAiChange();
      };

      this.editor.config.onSelectionChange = (newSelection) => {
        this.handleCloseAiChange();
        /**
          newSelection: {
            text:'wangeditor', // 当前选择文本
            html: '<p>wangeditor</p>', // 当前选中的html
            selection: selection, // 原生selection对象
          }
        */
      };

      setTimeout(() => {
        const textareaDom = document.getElementById('wangeditor');
        textareaDom.addEventListener('mouseup', this.handleSelection);
      }, 1000);

      this.readOnly && this.editor.disable();
    },

    handleReplaceAi() {
      const value = this.editor.selection.getSelectionText();
      if (!value) return;
      this.editor.cmd.do('insertHTML', `<span>${this.aiTextAfter}</span>`);
      this.handleCloseAiChange();
    },

    handleWriteAi() {
      this.handleTrack('paragraph_ai_rewrite');
      const value = this.editor.selection.getSelectionText();
      if (!value) return this.handleCloseAiChange();
      this.aiTextBefore = value;
      this.handleMakeAi({
        aiRewriteType: 'SINGLE_SENTENCE_REWRITE',
        content: value,
      });
    },

    handleRewriteAi() {
      this.handleMakeAi({
        aiRewriteType: 'COMMON',
        content: this.aiTextBefore,
      });
    },

    async handleMakeAi(params) {
      this.aiLoading = true;
      const { data, code, message } = await api['getAiRewriteStory'](params);
      if (code == 200) {
        this.aiLoading = false;
        this.aiTextAfter = data.rewriteContent;
      } else {
        this.$message.error(message);
        this.handleCloseAiChange();
      }
    },

    async handleSelection({ clientX, clientY }) {
      setTimeout(() => {
        const floating = document.getElementById('floating');
        const value = this.editor.selection.getSelectionText();
        if (!value) {
          this.handleCloseAiChange();
          return;
        }

        if (this.aiLoading) return;

        if (this.aiTextAfter) return;

        const virtualEl = {
          getBoundingClientRect() {
            return {
              width: 0,
              height: 0,
              x: clientX,
              y: clientY,
              left: clientX,
              right: clientX,
              top: clientY,
              bottom: clientY,
            };
          },
        };

        this.showAiBtn = true;

        computePosition(virtualEl, floating, {
          placement: 'top-start',
          middleware: [offset(5), flip(), shift()],
        }).then(({ x, y }) => {
          Object.assign(floating.style, {
            top: `${y}px`,
            left: `${x}px`,
          });
        });
      }, 100);
    },

    handleCloseAiChange() {
      this.showAiBtn = false;
      this.aiLoading = false;
      this.aiTextBefore = '';
      this.aiTextAfter = '';
    },

    handleAddTagClick() {
      this.showHashTag = true;
    },

    handleHashTagSearch: debounce(
      function (value) {
        if (value) {
          if (!this.noteTitle) return this.$message.error('请输入文案标题');
          if (!this.noteString) return this.$message.error('请输入文案内容');
          const params = {
            noteDetail: this.noteString,
            noteTitle: this.noteTitle,
            keyword: value,
          };
          this.searchTagList = [];
          this.showTip = false;
          this.hashTagLoading = true;
          this.handleRequest(
            'getHashTagSearch',
            (data) => {
              if (data.length) {
                this.searchTagList = data;
              } else {
                this.showTip = true;
              }
            },
            params
          ).finally(() => (this.hashTagLoading = false));
        }
      },
      500,
      true
    ),
    handleHashTagChange(value) {
      const item = this.searchTagList.find((val) => val.id === value);
      const index = this.tagList.findIndex((val) => val.id === value);
      if (index === -1) {
        this.tagList.push(item);
      } else {
        this.$message.info('该话题已存在');
      }
      this.newHashTag = undefined;
      this.showHashTag = false;
    },
    handleHashTagBlur() {
      this.showTip = false;
      this.showHashTag = false;
      this.newHashTag = undefined;
    },
    handleSave() {
      this.$emit('handleSave', {
        noteTitle: this.noteTitle,
        noteDetail: this.noteString,
        xhsTopicList: this.tagList,
      });
    },
    handlePublish() {
      if (!this.noteTitle || !this.noteString) return this.$message.error('标题或正文不能为空');
      this.$emit('handlePublish', {
        noteTitle: this.noteTitle,
        noteDetail: this.noteString,
      });
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    handleBackJump() {
      this.$router.replace({ name: 'copywritingList' });
    },
    onWeekContextMenuClick({ key: eventState }) {
      this.handleTrack('ai_rewirte', {
        rewrite_type: this.rewriteTypeList.find((type) => type.id == eventState)?.aiRewriteName || undefined,
      });
      if (!this.noteString.trim().length) return this.$message.info('无可改写内容');
      this.$emit('aiRewrite', eventState, this.noteString);
    },
    closeRepeat() {
      this.isNoteCheckRepeat = false;
      this.$emit('closeRepeat');
    },
    closeCheckError() {
      this.isNoteCheckError = false;
      this.$emit('closeError');
    },
    handleTextToImg() {
      if (!this.noteString.trim()) return this.$message.info('请先输入文案');
      const data = { title: this.noteTitle, text: this.noteString };
      api.saveImageList({ configType: 'textToImg', data }).then((res) => {
        if (res.code == 200) {
          window.open(`${process.env.VUE_APP_TEMPLATE_EDITOR}?tab=textToImg&textToImgId=${res.data}`, '_blank');
          // window.open(`http://localhost:3000/?tab=textToImg&textToImgId=${res.data}`, '_blank');
        } else {
          this.$message.error(`提交数据失败，${res.message}`);
        }
      });
    },
    handleTrack(trackEvent, trackParams = {}) {
      const to = this.$route;
      trackRequest(to, trackEvent, trackParams);
    },
    // ------------------------------------话题抽屉相关
    async handleTagSearch() {
      if (this.tagKeyword.trim()) {
        this.showTagSearch = true;
        this.localRes = [];
        this.xhsRes = [];
        this.localTagSearch();
        this.xhsCheck && this.xhsTagSearch();
      }
    },
    async localTagSearch() {
      this.localResTips = undefined;
      this.localLoading = true;
      const { code, data, message } = await api
        .getXhsLocalTopicList(this.tagKeyword)
        .finally(() => (this.localLoading = false));
      if (code === 200) {
        this.localRes = data;
        if (!this.localRes.length) this.localResTips = '无匹配结果，可尝试小红书搜索';
      } else {
        this.localResTips = message;
      }
    },
    async xhsTagSearch() {
      this.xhsResTipsType = undefined;
      this.xhsLoading = true;
      const params = {
        noteDetail: this.noteString,
        noteTitle: this.noteTitle,
        keyword: this.tagKeyword,
      };
      const { code, data, message } = await api.getHashTagSearch(params).finally(() => (this.xhsLoading = false));
      if (code === 200) {
        this.xhsRes = data;
        if (!this.xhsRes.length) this.xhsResTipsType = 1;
      } else {
        this.xhsResTipsType = 2;
      }
    },
    isInTagList(id) {
      return this.tagList.findIndex((tag) => tag.id === id);
    },
    handleTagClick(item) {
      if (this.isInTagList(item.id) === -1) {
        this.tagList.push(item);
      } else {
        this.tagList.splice(this.isInTagList(item.id), 1);
      }
    },
    handleTagEdit() {
      this.showTagModal = true;
      this.xhsDisabled = false;
      this.xhsDisabledTips = '';
      this.localRes = [];
      this.xhsRes = [];
      this.showTagSearch = false;
      this.tagKeyword = undefined;
      this.localResTips = undefined;
      this.xhsResTipsType = undefined;
      this.localLoading = false;
      this.xhsLoading = false;
      // if (!this.noteTitle || !this.noteString) {
      //   this.xhsDisabled = true;
      //   this.xhsDisabledTips = '请先输入标题和内容';
      //   return;
      // }
      const hasAuthor = this.mediaList?.filter((m) => m.p1OauthStatus === 1) || [];
      if (!hasAuthor.length) {
        this.xhsDisabled = true;
        this.xhsDisabledTips = '需先完成小红书授权';
        this.xhsCheck = false;
        return;
      }
      this.xhsCheck = true;
    },
    async getAuthorMediaList() {
      this.mediaList = [];
      this.mediaLoading = true;
      await this.handleRequest('getXhsAuthorizeList', (data) => {
        this.mediaList = data;
      }).finally(() => (this.mediaLoading = false));
    },
    async updateMediaList() {
      await this.getAuthorMediaList();
      const hasAuthor = this.mediaList?.filter((m) => m.p1OauthStatus === 1) || [];
      if (!this.mediaList.length || !hasAuthor.length) {
        this.xhsCheck = false;
        this.xhsDisabled = true;
        this.xhsDisabledTips = '需先完成小红书授权';
      } else {
        this.xhsDisabled = false;
        this.xhsDisabledTips = '';
      }
    },
    handleCheckSenseWord() {
      this.btnLoading = true;
      this.editor.disable();
      handleNoteCheck(this.noteString)
        .then(async ({ newNote, oldNote }) => {
          this.saveLoading = false;
          // 编辑器赋值
          // this.noteString = newNote || '';
          const noteContent = newNote.replace(/\n/g, '<br />');
          this.noteContent = noteContent;
          this.editor.txt.html(noteContent);
          this.isNoteCheckRepeat = false;

          if (newNote !== oldNote) {
            this.isNoteCheckError = true;
          } else {
            this.isNoteCheckError = false;
          }
        })
        .finally(() => {
          this.btnLoading = false;
          this.editor.enable();
        });
    },
    useLocalTag() {
      this.tagList = cloneDeep(this.localTagList);
    },
  },
};
</script>

<style lang="less" scoped>
.container-top {
  padding-bottom: 15px;
  border-radius: 6px;
  background-color: #fff;
}
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.bottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.ml-5 {
  margin-left: 5px;
}

.red {
  color: red !important;
}

.editor-style {
  /deep/ .w-e-text-container {
    min-height: 500px;
    width: 100%;
  }
}

.tag-line {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  color: #ababab;
}

.input-class /deep/ .ant-input:not(:last-child) {
  padding-right: 100px !important;
}

.word-count {
  position: absolute;
  top: 5px;
  right: 30px;
  margin-left: 10px;
  color: #999999;
  z-index: 1;
}

.ai_rewrite {
  position: relative;
  .ai_rewrite_panel {
    position: absolute;
    padding: 10px;
    /* bottom: -70px; */
    width: 300px;
    min-height: 200px;
    background-color: #fff;
    z-index: 3;
    border: 1px solid #dbdbdb;
  }
}

/deep/ .ant-checkbox-group-item {
  margin-bottom: 8px;
}

#floating {
  position: absolute;
  z-index: 3;
}

/deep/ .ant-radio-wrapper > span:nth-child(2) {
  white-space: break-spaces;
}

.media-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  color: #000;
  max-height: 500px;
  overflow-y: auto;
}

.media-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 10px;
  background-color: #fff;

  .media-left {
    display: flex;

    .left-left {
      width: 50px;
      height: 50px;
      border-radius: 25px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .left-right {
      flex: 1;

      .right-txt {
        justify-content: start;

        .logo {
          width: 20px;
          height: 20px;
          border-radius: 10px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.sync-err {
  margin: 0 auto;
  width: 100px;
  padding-left: 15px;
  border-radius: 5px;
  color: #f59a23;
  background-color: #facd91;
}

.sync-succ {
  margin: 0 auto;
  width: 100px;
  padding-left: 15px;
  border-radius: 5px;
  color: #5ac822;
  background-color: #caf982;
}

.line-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tag_title {
  margin-bottom: 10px;
  font-size: 15px;
  color: #8d8d8d;
}

.tag_list {
  display: flex;
  flex-wrap: wrap;
  padding-left: 12px;
  margin-bottom: 15px;

  .tag_item {
    display: flex;
    justify-content: space-between;
    width: 47%;
    padding: 5px 9px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 15px 8px 0;
    border: 1px solid #ccc;
    user-select: none;
  }

  .check_item {
    border: 1px solid #52c41a;
    background: #52c41a0a;
  }
}

.tips_line {
  margin: auto;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
</style>
