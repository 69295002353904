<template>
  <span>
    {{ message }}
    <span class="link" v-show="link" @click="handleJump">
      {{ pageName }}
      <a-icon class="link" style="margin-left: 5px" type="block" />
    </span>
  </span>
</template>

<script>
export default {
  name: 'messageText',
  props: {
    message: {
      default: null,
    },
    pageName: {
      default: null,
    },
    link: {
      default: null,
    },
  },
  methods: {
    handleJump() {
      window.open(this.link, '_blank');
    },
  },
};
</script>

<style lang="less" scoped>
.link {
  color: rgb(135, 150, 235);
  cursor: pointer;
}
</style>
