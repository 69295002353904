import xhsAgencyApi from '@/api/xhsAgencyApi';
import { highlightString } from '@/utils/index';
import { message } from 'ant-design-vue';
export function handleNoteCheck(val, option = { showMsg: true }) {
  const content = val;
  return new Promise((resolve, reject) => {
    xhsAgencyApi
      .noteCheckApi({ content })
      .then((res) => {
        if (res.code === 200) {
          const payload = { newNote: val, oldNote: val };
          if (res.data.length > 0) {
            if (option.showMsg) {
              message.warning('文案中存在高风险词，已高亮处理，请修改');
            }
            payload.newNote = highlightString(content, res.data);
          }
          resolve(payload);
        } else {
          message.warning(res.message);
          reject(res.message);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
