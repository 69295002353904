<template>
  <div class="publish">
    <!-- 媒体号弹窗 -->
    <a-modal
      v-model="showMedia"
      title="选择媒体号"
      @cancel="handleMediaCancel"
      @ok="handleMediaOk"
      :confirmLoading="mediaLoading"
    >
      <a-row type="flex" justify="space-between">
        <a-col :span="17">
          <a-input
            placeholder="请输入媒体昵称"
            style="margin-bottom: 5px"
            v-model="searchName"
            @change="handleSearch"
          ></a-input>
        </a-col>
        <a-col :span="6">
          <a-select
            style="width: 100%"
            v-model="masterAccountUsername"
            placeholder="请选择添加人"
            show-search
            @change="getMediaList"
            option-filter-prop="children"
          >
            <a-select-option v-for="item in managerList" :key="item">{{ item }}</a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <div class="media-list">
        <div
          :class="[
            'media-item',
            { 'current-item': currentMedia.id === value.id },
            value.p1OauthStatus === 1 ? 'enable-item' : 'disabled-item',
          ]"
          v-for="value in mediaList"
          :key="value.id"
          @click="handleMediaClick(value)"
        >
          <div class="media-left">
            <div class="left-left" v-show="value.avatar">
              <img :src="value.avatar" alt="头像" referrerpolicy="no-referrer" />
            </div>
            <div class="left-right">
              <div style="margin-left: 15px">{{ value.nickname }}</div>
              <div class="line-flex right-txt">
                <div class="logo" v-show="value.authorId">
                  <img src="@/assets/icon/xhs_logo.png" alt="" />
                </div>
                <div>{{ value.authorId || '-' }}</div>
              </div>
            </div>
          </div>
          <div class="media-right">
            <a-tooltip>
              <template slot="title">
                <div>授权时间：{{ value.p1OauthTime || '-' }}</div>
              </template>
              <div class="sync-succ" v-show="value.p1OauthStatus === 1">
                笔记数据
                <a-icon style="margin-left: 5px" type="check-circle" />
              </div>
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                <div>失效时间：{{ value.p1OauthExpireTime || '-' }}</div>
              </template>
              <div class="sync-err" v-show="value.p1OauthStatus !== 1">
                笔记数据<a-icon style="margin-left: 5px" type="exclamation-circle" />
              </div>
            </a-tooltip>
          </div>
        </div>
      </div>
    </a-modal>

    <!-- 发布设置弹窗 -->
    <a-modal
      v-model="showPublish"
      title="发布设置"
      @cancel="handlePublishCancel"
      @ok="handlePublishOk"
      :confirmLoading="publishLoading"
    >
      <a-form-model ref="publishForm" :model="publishForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
        <a-form-model-item label="添加地点" porp="position">
          <a-select
            v-model="publishForm.position"
            placeholder="支持搜索相关地点信息"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            show-search
            allowClear
            @search="handlePositionSearch"
            @change="handleGetPosition"
            :loading="positionLoading"
            option-label-prop="label"
          >
            <a-select-option
              v-for="(value, index) in postionList"
              :key="index"
              :label="value.name"
              :value="value.poiId"
            >
              <div>{{ value.name }}</div>
              <div style="font-size: 12px; color: #cccccc; overflow: hidden; text-overflow: ellipsis">
                {{ value.subname }}
              </div>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="权限设置" porp="privacy">
          <a-radio-group v-model="publishForm.privacy">
            <a-radio value="PUBLIC">
              <span>公开</span>
              <span style="color: rgba(128, 128, 128, 0.661)">(所有人可见)</span>
            </a-radio>
            <a-radio value="PRIVATE">
              <span>私密</span>
              <span style="color: rgba(128, 128, 128, 0.661)">(仅自己可见)</span>
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="发布方式" porp="publishType">
          <a-radio-group v-model="publishForm.publishType">
            <a-radio :value="1"> 立即发布 </a-radio>
            <a-radio :value="2"> 定时发布 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-if="publishForm.publishType === 2" label="发布时间" porp="postTime">
          <a-date-picker
            :show-time="{ format: 'HH:mm' }"
            format="YYYY-MM-DD HH:mm"
            :disabledDate="disabledDate"
            placeholder="请选择发布时间"
            v-model="publishForm.postTime"
          />
        </a-form-model-item>
        <a-form-model-item label="IP地址">
          广东
          <!-- <a-select v-model="publishForm.ipName" placeholder="请选择地址" option-filter-prop="children" show-search>
            <a-select-option v-for="(value, index) in areaList" :key="index" :value="value.id">
              {{ value.name }}
            </a-select-option>
          </a-select> -->
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import xhsApi from '@/api/xhsAgencyApi';
import moment from 'moment';

export default {
  props: {
    copyWritingContentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showMedia: true,
      mediaLoading: false,
      mediaList: [],
      currentMedia: {},
      showPublish: false,
      publishForm: {
        position: undefined,
        publishType: 1,
        privacy: 'PUBLIC',
      },
      searchName: undefined,
      postionList: [],
      positionLoading: false,
      publishLoading: false,
      currentPosition: {},
      areaList: [],
      masterAccountUsername: undefined,
      managerList: [],
    };
  },
  mounted() {
    // this.getAreaList();
    this.init();
  },
  methods: {
    async init() {
      this.masterAccountUsername = this.$router.app.user.username;
      this.getManagerList();
      this.getMediaList();
    },
    handleSearch() {
      this.currentMedia = {};
      this.getMediaList();
    },
    async getManagerList() {
      const { code, data } = await xhsApi.getCurrentManagerList();
      if (code === 200) this.managerList = data;
    },
    async getMediaList() {
      const params = {
        page: 1,
        size: 100,
        status: 'NORMAL',
        nickname: this.searchName,
        masterAccountUsername: this.masterAccountUsername,
      };
      await this.handlePublishRequest(
        'getAgencyList',
        (data) => {
          this.mediaList = data.list;
        },
        params
      ).finally(() => (this.tableLoading = false));
    },
    async getAreaList() {
      const data = await xhsApi.getLevelArea();
      this.areaList = data.map((val) => ({ id: val.id, name: val.name }));
    },
    handleMediaCancel() {
      this.showMedia = false;
      this.$emit('closePublish');
    },
    async handleMediaOk() {
      if (!this.currentMedia.id) return this.$message.info('请选择媒体号');
      this.mediaLoading = true;
      const { code, data, message } = await xhsApi
        .getXHSCookies({ authorId: this.currentMedia.authorId })
        .finally(() => (this.mediaLoading = false));
      if (code === 200) {
        if (data.author_id) {
          this.showMedia = false;
          this.showPublish = true;
        } else {
          const _this = this;
          const h = this.$createElement;
          this.$info({
            title: '未对齐笔记',
            content: h('div', {}, [h('p', '统计数据不准确，无法登录小红书账号，需在风火轮App中对齐数据。')]),
            onOk() {
              _this.showMedia = false;
              _this.$emit('closePublish');
            },
          });
        }
      } else {
        this.$message.error(`${message}`);
      }
    },
    handleMediaClick(value) {
      if (value.p1OauthStatus !== 1) {
        return;
      } else {
        this.currentMedia = value;
      }
    },
    // --------------------------------------------------------------------发布设置
    handlePositionSearch(value) {
      if (value) {
        this.positionLoading = true;
        this.handlePublishRequest(
          'getPublishLocation',
          (data) => {
            this.postionList = data;
          },
          {
            authorId: this.currentMedia.authorId,
            keyword: value,
          }
        ).finally(() => (this.positionLoading = false));
      }
    },
    handleGetPosition(value) {
      if (value) {
        this.currentPosition = this.postionList.find((val) => val.poiId === value);
      } else {
        this.currentPosition = {};
      }
    },
    disabledDate(current) {
      const today = moment();
      const nextWeek = moment().add(7, 'days');
      return current && (current < today || current > nextWeek);
    },
    handlePublishCancel() {
      this.showPublish = false;
      this.$emit('closePublish');
    },
    handlePublishOk() {
      if (this.publishForm.publishType === 2 && !this.publishForm.postTime) return this.$message.info('请选择发布时间');
      let isMin = false;
      if (this.publishForm.publishType === 2) {
        const afterHour = moment().add(1, 'hour').valueOf();
        isMin = this.publishForm.postTime < afterHour;
      }
      if (isMin) return this.$message.info('必须选取1个小时后的时间');
      const { authorId, avatar, nickname } = this.currentMedia;
      const postLoc = this.publishForm.position
        ? {
            name: this.currentPosition.name,
            poiId: this.currentPosition.poiId,
            poiType: this.currentPosition.poiType,
            subname: this.currentPosition.subname,
          }
        : null;
      const postNoteSetting = {
        postLoc,
        postTime:
          this.publishForm.publishType === 1
            ? ''
            : `${moment(this.publishForm.postTime).format('YYYY-MM-DD HH:mm')}:00`,
        privacy: this.publishForm.privacy,
      };
      console.log(params);
      const params = {
        authorId,
        avatar,
        nickname,
        postNoteSetting,
        copyWritingContentId: this.copyWritingContentId,
      };
      this.publishLoading = true;
      this.handlePublishRequest(
        'handleCopywrittingPost',
        () => {
          // this.$message.success('发布成功');
          this.$emit('publishSucc');
        },
        params
      ).finally(() => (this.publishLoading = false));
    },
    async handlePublishRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await xhsApi[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.media-list {
  display: flex;
  flex-direction: column;
  height: 500px;
  overflow-y: auto;

  .enable-item {
    cursor: pointer;
  }

  .disabled-item {
    cursor: not-allowed;
  }

  .current-item {
    background-color: rgba(128, 42, 240, 0.531) !important;
  }

  .media-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 10px;
    background-color: #f2f2f2;

    .media-left {
      display: flex;

      .left-left {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .left-right {
        flex: 1;

        .right-txt {
          justify-content: start;

          .logo {
            width: 20px;
            height: 20px;
            border-radius: 10px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00152984;

    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #0015294c;
  }

  &::-webkit-scrollbar-corner {
    background: #fff;
  }

  .line-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.sync-err {
  margin: 0 auto;
  width: 100px;
  padding-left: 15px;
  border-radius: 5px;
  color: #f59a23;
  background-color: #facd91;
}

.sync-succ {
  margin: 0 auto;
  width: 100px;
  padding-left: 15px;
  border-radius: 5px;
  color: #5ac822;
  background-color: #caf982;
}
</style>
