<template>
  <a-drawer
    :title="drawerTitle"
    width="820"
    :closable="readOnly"
    :visible="visible"
    @close="onClose"
    :destroyOnClose="true"
    :maskClosable="readOnly"
  >
    <!-- 基础信息 -->
    <div class="titleLine">
      <div class="titleLineTop">
        <div class="topLeft">基础信息</div>
        <div class="topRight" @click="showMoreDetail = !showMoreDetail">{{ showMoreDetail ? '收起' : '展开' }}</div>
      </div>
      <div v-show="showMoreDetail">
        <a-form-model ref="detailFrom" layout="horizontal" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
          <a-row type="flex" justify="space-between" style="padding: 0 10px">
            <a-col :span="10">
              <a-form-model-item label="草稿名称" v-show="draftId || isLocalData || historyId">
                <a-input :disabled="readOnly" v-model.trim="drafName"></a-input>
              </a-form-model-item>
              <a-form-model-item label="笔记名称" v-show="noteId || knowledgeId || topicId || planId || pushId ">
                {{ noteInfo.noteTitle }}
              </a-form-model-item>
            </a-col>
            <a-col :span="10">
              <a-form-model-item label="选题">
                <TopicTreeInput
                  show-search
                  style="width: 100%"
                  v-model="noteInfo.topicSelectionTagIdList"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  :replaceFields="{ title: 'name' }"
                  placeholder="选题"
                  allow-clear
                  multiple
                  tree-default-expand-all
                  :maxTagCount="readOnly ? 10 : 1"
                  :disabled="readOnly"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between" style="padding: 0 10px">
            <a-col :span="10">
              <a-form-model-item label="品牌">
                <a-select
                  v-model="principalInfoList[0].principalId"
                  placeholder="请选择品牌"
                  show-search
                  option-filter-prop="children"
                  @change="handleAddPrincipalChange"
                  allowClear
                  :disabled="readOnly"
                >
                  <a-select-option v-for="item in principalList" :key="item.value" :value="item.value">{{
                    item.label
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="10">
              <a-form-model-item label="车系" prop="vehicleSeriesId">
                <a-select
                  :loading="seriesLoading"
                  v-model="vehicleSeriesInfoList[0].vehicleSeriesId"
                  placeholder="请选择车系"
                  show-search
                  option-filter-prop="children"
                  allowClear
                  :disabled="readOnly"
                  @change="handleAddSeriesChange"
                >
                  <a-select-option v-for="item in addSeriesList" :key="item.id">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </div>

    <!-- 发布信息 -->
    <div class="titleLine" v-show="noteId || $route.name === 'xhsNotePush'" style="margin-top: 15px">
      <div class="titleLineTop">
        <div class="topLeft">发布信息</div>
        <div class="topRight" @click="showMoreNote = !showMoreNote">{{ showMoreNote ? '收起' : '展开' }}</div>
      </div>
      <div v-show="showMoreNote" style="padding: 0 10px">
        <div class="noteLine">
          <div class="lineItem">
            <span class="noteTitle">添加地点</span>
            <span class="noteTxt" v-if="noteInfo.postLoc"
              >{{ noteInfo.postLoc.subname || '-' }}-{{ noteInfo.postLoc.name || '-' }}</span
            >
            <span v-else> - </span>
          </div>
          <div class="lineItem">
            <span class="noteTitle">权限设置</span>
            <span class="noteTxt">{{ noteInfo.privacy == 'PUBLIC' ? '公开(所有人可见)' : '私密(仅自己可见)' }}</span>
          </div>
        </div>
        <div class="noteLine">
          <div class="lineItem">
            <span class="noteTitle">发布时间</span>
            <span class="noteTxt">{{ noteInfo.publishTimeText }}</span>
          </div>
          <div class="lineItem">
            <span class="noteTitle">IP地址</span>
            <span class="noteTxt">广东</span>
          </div>
        </div>
        <div class="noteLine">
          <div class="lineItem">
            <span class="noteTitle">发布账号</span>
            <span class="noteTxt">
              <div style="display: flex" class="noteMedia">
                <div class="media-left">
                  <img :src="noteInfo.avatar" alt="头像" referrerpolicy="no-referrer" />
                </div>
                <div class="media-right">
                  <div
                    style="
                      margin-left: 15px;
                      width: 165px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    {{ noteInfo.nickname }}
                  </div>
                  <div class="line-flex right-txt">
                    <div class="logo">
                      <img src="@/assets/icon/xhs_logo.png" alt="" />
                    </div>
                    <div>{{ noteInfo.authorCode || '-' }}</div>
                  </div>
                </div>
              </div>
            </span>
          </div>
          <div class="lineItem">
            <span class="noteTitle">人设</span>
            <span class="noteTxt pSetting" v-if="noteInfo.personalityVo">
              <img v-if="noteInfo.personalityVo.photoUrl" :src="noteInfo.personalityVo.photoUrl" alt="" />
              <div>
                {{
                  noteInfo.personalityVo.personalityType == 'SALE'
                    ? '销售'
                    : noteInfo.personalityVo.personalityType == 'STORE'
                    ? '门店'
                    : '其他'
                }}
              </div>
            </span>
            <span v-else> - </span>
          </div>
        </div>
      </div>
    </div>

    <!-- 图片 -->
    <div class="titleLine" style="margin-top: 15px">
      <div class="titleLineTop">
        <div class="topLeft">笔记图片</div>
        <div class="topRight" @click="showMorePic = !showMorePic">{{ showMorePic ? '收起' : '展开' }}</div>
      </div>
      <div v-show="showMorePic" style="width: 100%; padding: 0 20px; background: #fff">
        <drawerPicComp
          ref="drawerPicComp"
          :picList="picList"
          :chooseTagList="chooseTagList"
          @updatePicList="updatePicList"
          :principalInfoList="principalInfoList"
          :vehicleSeriesInfoList="vehicleSeriesInfoList"
          :readOnly="readOnly"
        />
      </div>
    </div>

    <!-- 笔记 -->
    <div style="margin-bottom: 10px">
      <div style="font-weight: bold">笔记文案</div>
      <drawerNoteComp ref="drawerNoteComp" :readOnly="readOnly" />
    </div>

    <!-- 发布弹窗 -->
    <PublishComp
      v-if="showPublishModal"
      :copyWritingContentId="storyData.id"
      @closePublish="handleClosePublish"
      @publishSucc="handlePublishDone"
    />

    <div
      :style="{
        position: 'absolute',
        display: 'flex',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
        zIndex: 2,
        justifyContent: 'space-between',
      }"
    >
      <template v-if="historyId">
        <a-button type="primary" @click="handleUseDraft" style="margin-right: 15px"> 还原 </a-button>
      </template>
      <template v-else>
        <div v-show="readOnly && draftId">
          <a-button type="primary" @click="handleDrafEdit" style="margin-right: 15px" v-show="draftId"> 编辑 </a-button>
        </div>
        <div v-show="!readOnly && draftId">
          <a-button type="primary" @click="handleDrafSave" :loading="saveLoading"> 保存 </a-button>
          <!-- 草稿编辑发布笔记 -->
          <a-button type="primary" @click="handleDrafPublish" :loading="publishLoading" style="margin: 0 15px">
            发布
          </a-button>
          <a-button style="margin-right: 8px" @click="onClose"> 取消 </a-button>
        </div>
        <div v-show="!readOnly && isLocalData">
          <a-button type="primary" @click="handleDrafSave" :loading="saveLoading"> 保存 </a-button>
          <!-- ai作文发布笔记 -->
          <a-button type="primary" @click="handleDrafPublish" :loading="publishLoading" style="margin: 0 15px">
            发布
          </a-button>
          <a-button style="margin-right: 8px" @click="onClose"> 取消 </a-button>
        </div>
        <div v-show="noteId || topicId || knowledgeId || planId || (pushId && noteInfo.noteId)">
          <a-button type="primary" @click="handleArticleJump"> 查看原文 </a-button>
          <template v-if="!limited">
            <a-button type="primary" style="margin: 0 15px" @click="handleAiRewrite">
              <!-- {{ $route.name == 'articleDetail' ? '提取图文' : 'AI改写' }} -->
              提取文案
            </a-button>
            <a-button type="primary" style="margin-right: 15px" @click="handlePickPic"> 提取图片 </a-button>
            <a-button type="primary" @click="handlePush" v-show="!topicId"> 推送至选题库 </a-button>
          </template>
          <!-- <a-button style="margin-right: 8px" @click="onClose"> Cancel </a-button> -->
        </div>
        <a-button type="link" @click="showChildrenDrawer">
          {{ storyData.noteType === 'VIDEO' ? '查看视频' : '预览效果' }}
        </a-button>
      </template>
    </div>

    <!-- 预览 -->
    <a-drawer title="预览" width="360" :closable="false" :visible="childrenDrawer" @close="onChildrenDrawerClose">
      <div class="previewContainer">
        <div v-if="storyData.noteType === 'VIDEO'" class="pBottom">
          <video style="margin: auto 0" controls :src="previewVideoUrl"></video>
        </div>
        <div class="pBottom" v-else>
          <div class="picArousel">
            <a-carousel arrows v-if="picList.length">
              <div slot="prevArrow" style="z-index: 1; left: 0">
                <a-icon type="left" />
              </div>
              <div slot="nextArrow">
                <a-icon type="right" style="z-index: 1; right: 0" />
              </div>
              <div class="picArouselItems" v-for="(val, index) in picList" :key="index">
                <img :src="val.fileUrl" alt="" referrerpolicy="no-referrer" />
              </div>
            </a-carousel>
          </div>
          <div class="previewTitle">{{ previewTitle ? previewTitle : '请先填写标题' }}</div>
          <div class="previewContent">{{ previewContent }}</div>
          <div class="previewTag">
            <span class="pTags" v-for="(val, index) in previewTags" :key="index">#{{ val.name }}</span>
          </div>
          <div class="previewTime">{{ previewTime }} 09:14</div>
          <div class="fakeComment">
            <img src="../../assets/images/xhs_bg_bt.png" alt="" />
          </div>
        </div>
      </div>
    </a-drawer>

    <!-- 批量推送 -->
    <BatchPushToTopicModal ref="BatchPushToTopicModal" />

    <!-- 提取图片 -->
    <PickPicDrawer ref="PickPicDrawer" />
  </a-drawer>
</template>

<script>
import drawerPicComp from './drawerPicComp';
import drawerNoteComp from './drawerNoteComp';
import PublishComp from '@/pages/app/xhsAgency/copywritingList/components/PublishComp.vue';
import api from '@/api/xhsAgencyApi';
import moment from 'moment';
import MessageTextCompVue from '@/components/MessageTextComp.vue';
import TopicTreeInput from '@/components/xhsAgencyTool/TopicTreeInput';
import BatchPushToTopicModal from '@/components/xhsAgencyTool/BatchPushToTopicModal.vue';
import PickPicDrawer from '@/components/xhsAgencyTool/PickPicDrawer.vue';
import { handleNoteCheck } from '@/pages/app/xhsAgency/utils';
import { trackRequest } from '@/utils/track';

export default {
  props: {
    draftId: {
      default: null,
    },
    noteId: {
      default: null,
    },
    topicId: {
      default: null,
    },
    knowledgeId: {
      default: null,
    },
    planId: {
      default: null,
    },
    pushId: {
      default: null,
    },
    isLocalData: {
      default: false,
    },
    drawerEditData: {
      default: () => {},
    },
    readOnly: {
      default: true,
    },
    limited: {
      default: false,
    },
    historyId: {
      default: null,
    },
  },
  components: {
    drawerPicComp,
    drawerNoteComp,
    PublishComp,
    MessageTextCompVue,
    TopicTreeInput,
    BatchPushToTopicModal,
    PickPicDrawer,
  },
  data() {
    return {
      visible: true,
      childrenDrawer: false,
      drawerTitle: '笔记详情',
      // --------------------图片相关
      picList: [],
      chooseTagList: [],
      principalInfoList: [{ principalId: undefined }],
      vehicleSeriesInfoList: [{ vehicleSeriesId: undefined }],
      storyText: '',
      storyRole: '',
      wordCount: 0,
      showFirstSave: false,
      saveLoading: false,
      showPublishModal: false,
      isPublish: false,
      saveParams: {
        noteDetail: '',
        noteDetailHtml: '',
        noteTitle: '',
        name: '',
      },
      btnLoading: false,
      storyData: { id: null },
      isNew: false,
      showMorePic: true,
      showMoreNote: true,
      showMoreDetail: true,
      principalList: [],
      addSeriesList: [],
      seriesLoading: false,
      drafName: '',
      publishLoading: false,
      previewTitle: '',
      previewContent: '',
      previewTags: [],
      previewTime: moment().format('YYYY-MM-DD'),
      noteInfo: { topicSelectionTagIdList: [], postLoc: {}, personalityVo: {} },
      previewVideoUrl: '',
    };
  },
  created() {
    this.initPage();
  },
  methods: {
    // -------------------回显相关
    initPage() {
      this.fetchPrincipalList();
      if (this.draftId) {
        // 草稿回显
        this.drawerTitle = '草稿详情';
        this.initDrafEdit();
        return;
      }
      if (this.isLocalData) {
        this.drawerTitle = '草稿详情';
        this.initTempEdit();
        return;
      }
      if (this.noteId) {
        this.drawerTitle = '笔记详情';
        this.initNoteDetail('getNoteDetailById', this.noteId);
        return;
      }
      if (this.topicId) {
        this.drawerTitle = '笔记详情';
        this.initNoteDetail('getNoteDetailByTopicId', this.topicId);
        return;
      }
      if (this.knowledgeId) {
        this.drawerTitle = '笔记详情';
        this.initNoteDetail('getNoteDetailByKnowledgeId', this.knowledgeId);
        return;
      }
      if (this.planId) {
        this.drawerTitle = '笔记详情';
        this.initNoteDetail('getNoteDetailByPlanId', this.planId);
        return;
      }
      if (this.pushId) {
        this.drawerTitle = '推送详情';
        this.initNoteDetail('getNoteDetailByPushId', this.pushId);
        return;
      }
      if (this.historyId) {
        this.drawerTitle = '历史详情';
        this.initNoteDetail('getNoteDetailHistoryId', this.historyId);
        return;
      }
    },
    // 本地数据回显
    initTempEdit() {
      this.isNew = true;
      this.storyData = JSON.parse(JSON.stringify(this.drawerEditData));
      this.drafName = this.storyData?.aiStory?.slice(0, 15) || undefined;
      this.principalInfoList = [
        {
          principalId: this.storyData?.postParams?.principalId || undefined,
          principalName: this.storyData?.postParams?.principalName || undefined,
        },
      ];

      this.principalInfoList[0].principalId &&
        this.getSeriesList(this.principalInfoList[0].principalId, 'addSeriesList');

      this.vehicleSeriesInfoList = [
        {
          vehicleSeriesId: this.storyData?.postParams?.seriesId || undefined,
          vehicleSeriesName: this.storyData?.postParams?.seriesName || undefined,
        },
      ];

      this.picList = this.storyData.picList || [];

      setTimeout(() => {
        if (this.$refs.drawerNoteComp) {
          this.$refs.drawerNoteComp.noteTitle = this.storyData?.title || '';
          this.$refs.drawerNoteComp.noteString = this.storyData?.aiStory || '';
          const noteContent = this.storyData?.noteDetailHtml || this.storyData?.aiStory?.replace(/\n/g, '<br />') || '';
          this.$refs.drawerNoteComp.noteContent = noteContent;
          this.$refs.drawerNoteComp.editor.txt.html(noteContent);
          this.$refs.drawerNoteComp.tagList = this.storyData?.tagList || [];
        }
      }, 200);
    },
    // 草稿回显
    initDrafEdit() {
      const hide = this.$message.loading('查询中...', 0);
      this.handleRequest(
        'getCopywrittingDetail',
        (data) => {
          if (!data) {
            this.$message.error('查询不到相关数据');
            return;
          }
          this.storyData = JSON.parse(JSON.stringify(data));
          this.drafName = data.name || '';
          this.noteInfo.topicSelectionTagIdList = this.storyData.topicSelectionTagList?.map((val) => val.id) || [];
          this.chooseTagList = this.storyData.topicSelectionTagList?.map((val) => val.id) || [];
          this.principalInfoList = [
            {
              principalId: data.principalId,
              principalName: data.principalName,
            },
          ];

          this.vehicleSeriesInfoList = [
            {
              vehicleSeriesId: data.carSeriesId,
              vehicleSeriesName: data.carSeriesName,
            },
          ];

          this.principalInfoList[0].principalId &&
            this.getSeriesList(this.principalInfoList[0].principalId, 'addSeriesList');

          this.picList =
            data.materialArr?.map((val) => {
              return {
                id: Math.random(),
                showHover: false,
                fileUrl: val.previewUrl,
                fileId: val.fileId,
              };
            }) || [];
          if (this.picList.length) {
            this.$nextTick(() => {
              this.$refs.drawerPicComp && this.$refs.drawerPicComp.defineSortable();
            });
          }

          this.storyText = data.story;
          this.storyRole = data.role;
          this.wordCount = data.wordCount;

          setTimeout(() => {
            if (this.$refs.drawerNoteComp) {
              this.$refs.drawerNoteComp.noteTitle = data.noteTitle;
              this.$refs.drawerNoteComp.noteString = data.noteDetail;
              const noteContent = data.noteDetailHtml
                ? data.noteDetailHtml.replace(/\n/g, '<br />')
                : data.noteDetail.replace(/\n/g, '<br />');
              this.$refs.drawerNoteComp.noteContent = noteContent;
              this.$refs.drawerNoteComp.editor.txt.html(noteContent);
              this.$refs.drawerNoteComp.tagList = data.xhsTopicList || [];
            }
            hide();
          }, 200);

          this.handleTrack('getCopywrittingDetail', data); // 埋点
        },
        this.draftId
      ).finally(() => hide());
    },
    // 笔记详情回显
    initNoteDetail(apiName, id) {
      const hide = this.$message.loading('查询中...', 0);
      let params = id;
      if (apiName == 'getNoteDetailByPlanId') params = { noteId: id };
      if (apiName == 'getNoteDetailByPushId') params = { id };
      if (apiName == 'getNoteDetailHistoryId') params = id;
      this.handleRequest(
        apiName,
        (data) => {
          if (!data) {
            this.$message.error('查询不到相关数据');
            return;
          }
          this.storyData =
            apiName == 'getNoteDetailHistoryId'
              ? JSON.parse(JSON.stringify(data.updateData))
              : JSON.parse(JSON.stringify(data));
          console.log('this.storyData ->', this.storyData);
          this.drafName = this.storyData.name || '';
          this.noteInfo = {
            shareLink: this.storyData.shareLink || undefined,
            noteTitle: this.storyData.noteTitle || undefined,
            topicSelectionTagIdList: this.storyData.topicSelectionTagList?.length
              ? this.storyData.topicSelectionTagList.map((item) => item.id)
              : this.storyData.topicSelectionTagIdList?.length
              ? this.storyData.topicSelectionTagIdList
              : [],
            postLoc: this.storyData.postLoc || undefined,
            privacy: this.storyData.privacy || undefined,
            // postTime: this.storyData.postTime || undefined,
            avatar: this.storyData.avatar || undefined,
            nickname: this.storyData.nickname || undefined,
            authorCode: this.storyData.authorCode || undefined,
            personalityVo: this.storyData.personalityVo || undefined,
            noteId: this.storyData.noteId || undefined,
          };
          let publishTimeText = '';
          if (this.storyData.postTime) {
            const currentTime = moment();
            const postTime = moment(this.storyData.postTime);
            if (postTime.isBefore(currentTime)) {
              publishTimeText = this.storyData.postTime;
            } else {
              publishTimeText = `定时发布(${this.storyData.postTime})`;
            }
          } else {
            publishTimeText = this.storyData.publishTime;
          }
          this.noteInfo.publishTimeText = publishTimeText;
          console.log('this.noteInfo:', this.noteInfo);

          this.principalInfoList = [
            {
              principalId: this.storyData.principalId,
              principalName: this.storyData.principalName,
            },
          ];

          this.vehicleSeriesInfoList = [
            {
              vehicleSeriesId: this.storyData.carSeriesId,
              vehicleSeriesName: this.storyData.carSeriesName,
            },
          ];

          this.principalInfoList[0].principalId &&
            this.getSeriesList(this.principalInfoList[0].principalId, 'addSeriesList');

          this.picList =
            this.storyData.materialArr?.map((val) => {
              return {
                id: Math.random(),
                showHover: false,
                fileUrl: val.previewUrl,
                fileId: val.fileId,
              };
            }) || [];
          if (this.picList.length) {
            this.$nextTick(() => {
              this.$refs.drawerPicComp && this.$refs.drawerPicComp.defineSortable();
            });
          }

          this.storyText = this.storyData.story;
          this.storyRole = this.storyData.role;
          this.wordCount = this.storyData.wordCount;

          setTimeout(() => {
            if (this.$refs.drawerNoteComp) {
              this.$refs.drawerNoteComp.noteTitle = this.storyData.noteTitle;
              this.$refs.drawerNoteComp.noteString = this.storyData.noteDetail;
              const noteContent = this.storyData.noteDetailHtml
                ? this.storyData.noteDetailHtml.replace(/\n/g, '<br />')
                : this.storyData.noteDetail
                ? this.storyData.noteDetail.replace(/\n/g, '<br />')
                : '';
              this.$refs.drawerNoteComp.noteContent = noteContent;
              this.$refs.drawerNoteComp.editor.txt.html(noteContent);
              this.$refs.drawerNoteComp.tagList = this.storyData.xhsTopicList || [];
            }
            hide();
          }, 200);

          this.handleTrack(apiName, data); // 埋点
        },
        params
      ).finally(() => hide());
    },
    async fetchPrincipalList() {
      api.getBrandList().then((res) => {
        this.principalList =
          res.data?.map((item) => {
            return { label: item.principalName, value: item.id };
          }) || [];
      });
    },
    async handleAddPrincipalChange(id) {
      this.vehicleSeriesInfoList[0].vehicleSeriesId = undefined;
      this.vehicleSeriesInfoList[0].vehicleSeriesName = undefined;
      const principalName = this.principalList.find((ele) => ele.value === id)?.label;
      this.principalInfoList[0].principalName = principalName;
      let principalIds = id ? id : '';
      if (!id) this.addSeriesList = [];
      principalIds && (await this.getSeriesList(principalIds, 'addSeriesList'));
    },
    handleAddSeriesChange(id) {
      const vehicleSeriesName = this.addSeriesList.find((ele) => ele.id === id)?.name || '';
      this.vehicleSeriesInfoList[0].vehicleSeriesName = vehicleSeriesName;
    },
    getSeriesList(principalIds, typeList) {
      this[typeList] = [];
      if (!principalIds) return;
      this.seriesLoading = true;
      api
        .getNewTypeList({ principalIds })
        .then((res) => {
          this[typeList] = res.data?.map((val) => ({ id: val.id, name: val.name })) || [];
        })
        .finally(() => (this.seriesLoading = false));
    },
    // -------------------抽屉相关
    handleDrafEdit() {
      let routeData = this.$router.resolve({
        name: 'articleDetail',
        query: { draftId: this.draftId },
      });
      window.open(routeData.href, '_blank');
    },
    async handleDrafSave() {
      handleNoteCheck(this.$refs.drawerNoteComp.noteString).then(async ({ newNote, oldNote }) => {
        // 编辑器赋值
        this.$refs.drawerNoteComp.noteString = newNote || '';
        const noteContent = newNote.replace(/\n/g, '<br />') || '';
        this.$refs.drawerNoteComp.noteContent = noteContent;
        this.$refs.drawerNoteComp.editor.txt.html(noteContent);

        if (newNote !== oldNote) {
          this.$refs.drawerNoteComp.isNoteCheckError = true;
        } else {
          this.$refs.drawerNoteComp.isNoteCheckError = false;
        }

        if (this.$refs.drawerNoteComp.btnLoading) return this.$message.info('请稍后');
        const validate = this.handleValidate();
        console.log(this.saveParams);
        if (validate) {
          this.isPublish = false;
          this.saveLoading = true;
          await this.handleRequest(
            'handleSaveCopywriting',
            () => {
              this.$emit('updateList');
              if (this.$route.name === 'copywritingList') {
                this.$message.success('保存成功');
              } else {
                const link = this.$router.resolve({
                  name: 'copywritingList',
                });
                const messageComp = this.$createElement(MessageTextCompVue, {
                  props: { message: '已保存草稿，查看笔记可前往', pageName: '草稿箱', link: link.href },
                });
                this.$message.success({
                  content: messageComp,
                  key: 'darf',
                  duration: 3,
                });
              }
            },
            this.saveParams
          ).finally(() => (this.saveLoading = false));
        }
      });
    },
    async handleDrafPublish() {
      handleNoteCheck(this.$refs.drawerNoteComp.noteString).then(async ({ newNote, oldNote }) => {
        this.$refs.drawerNoteComp.noteString = newNote || '';
        const noteContent = newNote.replace(/\n/g, '<br />') || '';
        this.$refs.drawerNoteComp.noteContent = noteContent;
        this.$refs.drawerNoteComp.editor.txt.html(noteContent);

        if (newNote !== oldNote) {
          this.$refs.drawerNoteComp.isNoteCheckError = true;
          validate = false;
        } else {
          this.$refs.drawerNoteComp.isNoteCheckError = false;
          validate = true;
        }

        // 点击发布
        if (this.$refs.drawerNoteComp.btnLoading) return this.$message.info('请稍后');
        //   赋值/校验发布数据
        let validate = this.handleValidate(true);
        if (validate) {
          // 先保存后发布
          this.isPublish = true;
          this.publishLoading = true;
          await this.handleRequest(
            'handleSaveCopywriting',
            (id) => {
              if (this.isLocalData) this.storyData.id = id;
              this.showPublishModal = true;
            },
            this.saveParams
          ).finally(() => (this.publishLoading = false));
        }
      });
    },
    handleValidate(isPublish = false) {
      const id = this.storyData.id || null; // 有id则为编辑
      const noteTitle = this.$refs.drawerNoteComp.noteTitle;
      const noteDetail = this.$refs.drawerNoteComp.noteString;
      const xhsTopicList = this.$refs.drawerNoteComp.tagList;
      const noteDetailHtml = this.$refs.drawerNoteComp.noteContent;
      const materialArr = this.picList.map((val) => ({ fileId: val.fileId, previewUrl: val.fileUrl }));
      const topicSelectionTagIdList = this.noteInfo.topicSelectionTagIdList || [];

      if (isPublish && !this.picList.length) {
        this.$message.info('请至少选择一张图片');
        return false;
      }

      if (isPublish && !noteTitle) {
        !this.$message.info('标题不能为空');
        return false;
      }

      if (!noteDetail) {
        this.$message.info('文案不能为空');
        return false;
      }

      if (this.isNew) {
        // 新建笔记
        this.saveParams = {
          topicSelectionTagIdList,
          materialArr,
          principalId: this.principalInfoList[0].principalId || '',
          principalName: this.principalInfoList[0].principalName || '',
          carSeriesId: this.vehicleSeriesInfoList[0].vehicleSeriesId || '',
          carSeriesName: this.vehicleSeriesInfoList[0].vehicleSeriesName || '',
          // ----------------------------- 笔记文案
          noteTitle,
          noteDetail,
          noteDetailHtml,
          xhsTopicList,
          name: this.drafName ? this.drafName : noteDetail.slice(0, 15),
        };
      } else {
        this.saveParams = {
          id,
          name: !!id ? this.storyData.name : null,
          topicSelectionTagIdList,
          // ----------------------------- 图片
          materialArr,
          principalId: this.principalInfoList[0].principalId || '',
          principalName: this.principalInfoList[0].principalName || '',
          carSeriesId: this.vehicleSeriesInfoList[0].vehicleSeriesId || '',
          carSeriesName: this.vehicleSeriesInfoList[0].vehicleSeriesName || '',
          // ----------------------------- 内容需求
          storyType: !!id ? this.storyData.storyType : this.storyData.postParams.storyType,
          wordCount: !!id ? this.storyData.wordCount : this.storyData.postParams.wordCount,
          storyWay: !!id ? this.storyData.storyWay : this.storyData.postParams.storyWay,
          // ----------------------------- 人设
          customer: !!id ? this.storyData.customer : this.storyData.makeStoryParams.customer,
          occupation: !!id ? this.storyData.occupation : this.storyData.makeStoryParams.occupation,
          lifestyle: !!id ? this.storyData.lifestyle : this.storyData.makeStoryParams.lifestyle,
          setting: !!id ? this.storyData.setting : this.storyData.makeStoryParams.setting,
          featureArr: id
            ? this.storyData.featureArr?.map((val) => ({ thdFeature: val.thdFeature }))
            : this.storyData.makeStoryParams.features?.map((val) => ({ thdFeature: val.thd_feature })),
          // ----------------------------- 故事
          role: !!id ? this.storyData.role : this.storyData.makeStoryParams.role,
          story: !!id ? this.storyData.story : this.storyData.makeStoryParams.story,
          // ----------------------------- 笔记文案
          noteTitle,
          noteDetail,
          noteDetailHtml,
          xhsTopicList,
          name: this.drafName ? this.drafName : noteDetail.slice(0, 15),
        };
      }
      return true;
    },
    handleClosePublish() {
      this.showPublishModal = false;
    },
    handlePublishDone() {
      const link = this.$router.resolve({
        name: 'xhsNoteAnalysis',
      });
      const messageComp = this.$createElement(MessageTextCompVue, {
        props: { status: 'note', link },
      });
      this.$message.success({
        content: messageComp,
        key: 'note',
        duration: 3,
      });
      this.showPublishModal = false;
      this.$emit('updateList');
    },
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.$emit('closeDrawer');
    },
    showChildrenDrawer() {
      if (this.storyData.noteType && this.storyData.noteType === 'VIDEO') {
        return window.open(this.storyData.videoUrl, '_blank', 'noreferrer=yes');
      }
      if (!this.picList.length) return this.$message.error('至少需要一张图片');
      this.childrenDrawer = true;
      this.previewTitle = this.$refs.drawerNoteComp.noteTitle;
      this.previewContent = this.$refs.drawerNoteComp.noteString;
      this.previewTags = this.$refs.drawerNoteComp.tagList;
    },
    onChildrenDrawerClose() {
      this.childrenDrawer = false;
      this.previewVideoUrl = '';
    },
    // --------------------图片相关
    updatePicList(picList) {
      this.picList = [];
      this.picList = picList;
      this.$nextTick(() => {
        picList.length && this.$refs.drawerPicComp && this.$refs.drawerPicComp.defineSortable();
      });
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    // ---------------------笔记相关
    handleArticleJump() {
      const link = this.noteInfo.shareLink;
      if (!link) return this.$message.error(`笔记链接有误`);
      window.open(link, '_blank');
    },
    handleAiRewrite() {
      if (!this.noteInfo.noteId) return this.$message.error(`笔记ID有误`);

      // 编辑页改写
      if (this.$route.name == 'articleDetail') {
        console.log(this.storyData);
        this.$emit('handleRewrite', this.noteInfo.noteId);
        this.$emit('closeDrawer');
        return;
      }

      // 跳转AI改写
      let routeData = this.$router.resolve({
        name: 'articleDetail',
        query: { noteId: this.noteInfo.noteId },
      });
      window.open(routeData.href, '_blank');
      trackRequest(this.$route, 'extract_text');
    },
    handlePickPic() {
      this.$refs['PickPicDrawer'].openDrawer(this.picList);
      trackRequest(this.$route, 'extract_image');
    },
    handlePush() {
      if (!this.storyData.noteId) return this.$message.error('笔记ID有误');
      const dataList = [
        {
          id: this.storyData.id,
          carSeriesId: this.storyData.carSeriesId,
          carSeriesName: this.storyData.carSeriesName,
          noteId: this.storyData.noteId,
          noteTitle: this.storyData.noteTitle,
          principalId: this.storyData.principalId,
          principalName: this.storyData.principalName,
        },
      ];
      const createSource = this.noteId ? 'NOTE_ANALYSE' : this.knowledgeId ? 'KNOWLEDGE' : null;
      this.$refs['BatchPushToTopicModal'].openModal({
        type: '笔记',
        dataList,
        createSource,
      });
    },
    handleTrack(NOTE_TYPE, NOTE_DATA) {
      const trackTypeList = {
        getNoteDetailById: { note_type: '小红书笔记', id_name: 'noteId' },
        getNoteDetailByPushId: { note_type: '笔记推送', id_name: 'noteId' },
        getNoteDetailByTopicId: { note_type: '选题库', id_name: 'noteId' },
        getNoteDetailByKnowledgeId: { note_type: '知识库', id_name: 'noteId' },
        getNoteDetailByPlanId: { note_type: '运营执行', id_name: 'noteId' },
        getCopywrittingDetail: { note_type: '草稿', id_name: 'id' },
      };
      if (!trackTypeList[NOTE_TYPE]) return;
      const to = this.$route;
      const params = {
        note_type: trackTypeList[NOTE_TYPE]?.note_type || undefined,
        id: NOTE_DATA[trackTypeList[NOTE_TYPE]?.id_name] || undefined,
      };
      trackRequest(to, 'view_drawer', params);
    },
    handleUseDraft(){
      this.$emit('useDraft', this.storyData)
    }
  },
};
</script>

<style lang="less" scoped>
.titleLine {
  display: flex;
  flex-direction: column;

  .titleLineTop {
    display: flex;
    justify-content: space-between;

    .topLeft {
      font-weight: bold;
    }
    .topRight {
      color: #2d9aff;
      cursor: pointer;
    }
  }
}

/deep/ .ant-form-item {
  margin-bottom: 5px;
}

.previewContainer {
  position: relative;
  width: 100%;
  height: 680px;
  background-image: url('../../assets/images/xhs_bg.png');
  background-repeat: no-repeat;
  background-size: cover;

  .pBottom {
    position: absolute;
    top: 77px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 535px;
    overflow-y: auto;

    .picArousel {
      width: 100%;
      height: 180px;

      .picArouselItems {
        height: 180px;
        overflow: hidden;

        img {
          margin: 0 auto;
          max-width: 100%;
          max-height: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .previewTitle {
      margin-top: 5px;
      padding: 0 10px;
      font-weight: 600;
      font-size: 12px;
      color: #333;
    }

    .previewContent {
      padding: 0 10px;
      margin: 5px 0;
      outline: none;
      font-size: 13px;
      word-wrap: break-word;
      white-space: pre-wrap;
    }

    .previewTag {
      display: flex;
      flex-wrap: wrap;
      padding: 0 10px;
      margin: 5px 0;
      font-size: 12px;

      .pTags {
        margin-right: 3px;
        color: #3a64ff;
      }
    }

    .previewTime {
      padding: 0 10px;
      font-size: 12px;
      margin: 5px 0;
      color: #666;
      font-size: 12px;
    }

    .fakeComment {
      margin: 0 auto;
      width: 98%;
      height: 196px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.ant-carousel {
  & .slick-arrow {
    width: 25px;
    height: 25px;
    color: #ccc;
    font-size: 25px;

    &::before {
      display: none;
    }
  }

  & .slick-next {
    right: 0;
  }
}

.noteLine {
  display: flex;

  .lineItem {
    flex: 1;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;

    .noteTitle {
      display: inline-block;
      width: 80px;
      margin-right: 10px;
      color: #7f7f7f;
      flex-shrink: 0;
    }

    .noteTxt {
      color: #333;
    }

    .pSetting {
      display: flex;
      align-items: center;
      img {
        margin-right: 15px;
        width: 50px;
        height: 50px;
      }
    }
  }
}

.noteMedia {
  .media-left {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .media-right {
    flex: 1;

    .right-txt {
      display: flex;
      align-items: center;
      justify-content: start;

      .logo {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
