<template>
  <!-- 我的图库弹窗 -->
  <a-modal
    v-model="show"
    title="从我的图库添加"
    :dialog-style="{ top: '15px' }"
    width="1002px"
    @cancel="handleStoreCancel"
    @ok="handleStoreOk"
  >
    <div class="center-line">
      <div>已选：{{ chooseList.length || 0 }} 个</div>
    </div>

    <a-spin :spinning="spinning" size="large" tip="加载中...">
      <div class="container-box">
        <div class="item" v-for="(val, ind) in dataList" :key="ind">
          <a-checkbox class="check-box" @change="onCheckChange(val)" :checked="val.checked"></a-checkbox>
          <div
            class="img-top"
            :style="{ 'background-image': `url(${val.preview})` }"
            @click="openBigImg(val.url, ind)"
          ></div>
        </div>
      </div>
      <base-pagination
        :currentPage="pagination.current"
        :pageSize="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
        :options="['15', '30', '50']"
      />
    </a-spin>
  </a-modal>
</template>

<script>
import api from '@/api/xhsAgencyApi';

export default {
  props: {
    isReplace: {
      default: false,
    }
  },
  data() {
    return {
      show: true,
      spinning: false,
      dataList: [],
      chooseList: [],
      pagination: {
        current: 1,
        pageSize: 15,
        total: 0,
      },
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      const params = {
        pageSize: this.pagination.pageSize,
        page: this.pagination.current,
      };
      this.spinning = true;
      await this.handleRequest(
        'getMyPicStoreList',
        (data) => {
          this.chooseList = [];
          this.dataList = data.list;
          this.pagination.total = data.total;
        },
        params
      ).finally(() => {
        this.spinning = false;
      });
    },
    handleStoreCancel() {
      this.show = false;
      this.$emit('closeStoreModal');
    },
    handleStoreOk() {
      if (!this.chooseList.length) return this.$message.info('请选择图片');
      if (this.isReplace && this.chooseList.length !== 1) return this.$message.info('仅支持单张图片');
      this.$emit('addPic', this.chooseList);
      this.handleStoreCancel();
    },
    onCheckChange(value) {
      // 保留选择的顺序 不放进下面遍历逻辑
      if (!value.checked) {
        // 选择图片
        if (this.isReplace && this.chooseList.length === 1) return this.$message.info('仅支持单张图片');
        this.chooseList.push({
          fileUrl: value.url,
          fileId: value.fileId,
        });
      } else {
        // 取消图片
        const index = this.chooseList.findIndex((val) => val.fileUrl == value.url);
        this.chooseList.splice(index, 1);
      }

      this.dataList.forEach((val) => {
        if (val.id === value.id) {
          val.checked = !val.checked;
        }
      });
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    // 打开大图
    openBigImg(url, index) {
      const $viewer = this.$viewerApi({
        options: {
          toolbar: true,
          initialViewIndex: index,
          viewed(e) {},
        },
        images: this.dataList.map((val) => val.url),
      });
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
  },
};
</script>

<style lang="less" scoped>
.center-line {
  display: flex;
  margin-bottom: 10px;
}

.container-box {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  height: 540px;
  overflow-y: auto;

  .item {
    position: relative;
    width: 200px;
    height: 232px;
    border: 1px solid #e9e9e9;
    margin: 0 13px 20px;
    background-color: #fff;

    .check-box {
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 1;

      /deep/ .ant-checkbox-inner {
        width: 25px;
        height: 25px;

        &:after {
          left: 33%;
        }
      }
    }

    .img-top {
      position: relative;
      width: 100%;
      height: 230px;
      background-repeat: no-repeat;
      cursor: pointer;
      background-size: cover;
      background-position: top center;
    }
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00152984;

    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #0015294c;
  }

  &::-webkit-scrollbar-corner {
    background: #fff;
  }
}
</style>
