<template>
  <a-modal
    :title="title"
    :maskClosable="false"
    v-model="visible"
    width="560px"
    :confirmLoading="saveLoading"
    @ok="save"
    @cancel="onClose"
  >
    <topic-tree-input
      show-search
      style="width: 100%; margin-bottom: 15px"
      v-model="topicSelectionTagIdList"
      :tree-data="tagList"
      :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
      :replaceFields="{ title: 'name' }"
      placeholder="选题"
      allow-clear
      multiple
      tree-default-expand-all
      :maxTagCount="3"
    >
    </topic-tree-input>
    <template v-if="this.title !== '修改选题' && this.type !== '批量修改'">
      <!-- 非选题库弹窗 -->
      <BrandSelectInput
        style="width: 100%; margin-bottom: 15px"
        placeholder="品牌"
        v-model="detailForm.principalIdListData"
        show-search
        :maxTagCount="2"
        option-filter-prop="children"
        allowClear
      />
      <CarSeriesSelectInput
        style="width: 100%; margin-bottom: 15px"
        :principalIds="principalIds"
        v-model="detailForm.carSeriesIdListData"
        placeholder="车系"
        show-search
        :filter-option="false"
        :maxTagCount="2"
        allowClear
      />
    </template>

    <template v-if="this.title !== '修改选题' && this.type !== '批量修改'">
      <!-- 非选题库弹窗 -->
      <div style="margin: 10px 0">
        推送至：
        <a-radio-group v-model="detailForm.topicSelectionType">
          <a-radio value="OPERATE" key="OPERATE">运营爆文</a-radio>
          <a-radio value="PLATFORM" key="PLATFORM">平台爆文</a-radio>
        </a-radio-group>
      </div>
    </template>

    <template v-if="type === '笔记分析'">
      <div>推送至选题库后将优先作为材料生产半成品</div>
    </template>
    <div style="color: #808080" v-if="type == '批量修改'">将覆盖原选题</div>
  </a-modal>
</template>

<script>
import TopicTreeInput from '@/components/xhsAgencyTool/TopicTreeInput';
import BrandSelectInput from '@/components/xhsAgencyTool/BrandSelectInput';
import CarSeriesSelectInput from '@/components/xhsAgencyTool/CarSeriesSelectInput';

import api from '@/api/xhsAgencyApi';

export default {
  components: { TopicTreeInput, BrandSelectInput, CarSeriesSelectInput },
  data() {
    return {
      visible: false,
      type: '',
      tagList: [],
      originId: '',
      dataList: [],
      topicSelectionTagIdList: [],
      saveLoading: false,
      createSource: '',
      detailForm: {
        principalIdListData: [],
        carSeriesIdListData: [],
        topicSelectionType: 'OPERATE',
      },
      // topicSelectionType: undefined,
    };
  },
  computed: {
    title() {
      if (this.type === '修改' || this.type === '文案模板') {
        return '修改选题';
      } else if (this.type === '笔记分析') {
        return `推送${this.dataList.length}个笔记到选题库`;
      } else if (this.type === '批量修改') {
        return `批量修改${this.dataList.length}个数据选题`;
      } else {
        return `推送${this.dataList.length}个${this.type}到选题库`;
      }
    },
    principalIds() {
      if (!this.detailForm.principalIdListData) return [];
      const isArray = Array.isArray(this.detailForm.principalIdListData);
      let principalIdListData = this.detailForm.principalIdListData;
      if (!isArray) {
        principalIdListData = [this.detailForm.principalIdListData];
      }
      return principalIdListData.length > 0
        ? principalIdListData.map((item) => {
            const arr = item.split(',');
            return arr.length > 0 ? arr[0] : '';
          })
        : [];
    },
  },
  methods: {
    /**
     * 打开弹窗
     * @param {string} type 类型 笔记/图片/修改
     * @param {Array} dataList 选择的参数
     * @param {Array} selectList 选择的标签
     * @param {string} createSource 笔记分析-笔记来源
     * @param {string} topicSelectionType 选题库-批量修改选题-当前类型
     */
    openModal({ type, dataList, selectList, createSource, topicSelectionType }) {
      Object.assign(this.$data, this.$options.data());
      this.type = type;
      this.dataList = dataList;
      this.createSource = createSource;
      this.topicSelectionTagIdList = selectList || [];
      this.detailForm.topicSelectionType = topicSelectionType || 'OPERATE';
      this.visible = true;
    },
    /**
     * 关闭弹窗
     */
    onClose() {
      this.visible = false;
    },
    /**
     * 推送图片
     */
    async pushImg() {
      try {
        this.saveLoading = true;
        const { code, message } = await api.pushImgToTopic({
          topicSelectionTagIdList: this.topicSelectionTagIdList,
          imageInfoList: this.dataList.map((item) => ({
            fileId: item.fileId,
            fileTitle: item.cmsFile.fileTitle,
            previewUrl: item.cmsFile.fileUrl,
            principalId: item.attrValue.principalInfoList ? item.attrValue.principalInfoList[0].attrValue : '',
            principalName: item.attrValue.principalInfoList ? item.attrValue.principalInfoList[0].attrName : '',
            carSeriesId: item.attrValue.vehicleSeriesInfoList ? item.attrValue.vehicleSeriesInfoList[0].attrValue : '',
            carSeriesName: item.attrValue.vehicleSeriesInfoList ? item.attrValue.vehicleSeriesInfoList[0].attrName : '',
          })),
        });
        this.saveLoading = false;
        if (code === 200) {
          this.$message.success(`已加入选题库`);
          this.$emit('success');
          this.onClose();
        } else {
          return this.$message.error(`${message}`);
        }
      } catch (error) {
        this.saveLoading = false;
        console.log(error);
      }
    },
    /**
     * 推送笔记
     */
    async pushNote() {
      if (!this.detailForm.topicSelectionType) return this.$message.warning('请选择类型');
      try {
        this.saveLoading = true;
        let principalId, principalName, carSeriesId, carSeriesName;
        if (this.detailForm.principalIdListData.length) {
          principalId = this.detailForm.principalIdListData.split(',')[0];
          principalName = this.detailForm.principalIdListData.split(',')[1];
        }
        if (this.detailForm.carSeriesIdListData.length) {
          carSeriesId = this.detailForm.carSeriesIdListData.split(',')[0];
          carSeriesName = this.detailForm.carSeriesIdListData.split(',')[1];
        }
        const { code, message } = await api.handlePushNote({
          topicSelectionTagIdList: this.topicSelectionTagIdList,
          noteList: this.dataList.map((item) => ({
            id: item.id || '',
            carSeriesId: carSeriesId ? carSeriesId : item.carSeriesId,
            carSeriesName: carSeriesName ? carSeriesName : item.carSeriesName,
            noteId: item.noteId || '',
            noteTitle: item.noteTitle || '',
            principalId: principalId ? principalId : item.principalId,
            principalName: principalName ? principalName : item.principalName,
            noteSource: item.noteSource || '',
          })),
          createSource: this.createSource,
          topicSelectionType: this.detailForm.topicSelectionType,
        });
        this.saveLoading = false;
        if (code === 200) {
          this.$message.success(`已加入选题库`);
          this.$emit('success');
          this.onClose();
        } else {
          return this.$message.error(`${message}`);
        }
      } catch (error) {
        this.saveLoading = false;
        console.log(error);
      }
    },
    async pushNoteAnalyze() {
      if (!this.detailForm.topicSelectionType) return this.$message.warning('请选择类型');
      try {
        this.saveLoading = true;
        let principalId, principalName, carSeriesId, carSeriesName;
        if (this.detailForm.principalIdListData.length) {
          principalId = this.detailForm.principalIdListData.split(',')[0];
          principalName = this.detailForm.principalIdListData.split(',')[1];
        }
        if (this.detailForm.carSeriesIdListData.length) {
          carSeriesId = this.detailForm.carSeriesIdListData.split(',')[0];
          carSeriesName = this.detailForm.carSeriesIdListData.split(',')[1];
        }
        const params = {
          topicSelectionTagIdList: this.topicSelectionTagIdList,
          noteList: this.dataList.map((item) => ({
            id: item.id || '',
            noteId: item.noteId || '',
            noteTitle: item.noteTitle || '',
            principalId,
            principalName,
            carSeriesId,
            carSeriesName,
            noteSource: 'NOTE',
          })),
          createSource: this.createSource,
          topicSelectionType: this.detailForm.topicSelectionType,
        };
        const { code, message } = await api.handlePushNote(params);
        this.saveLoading = false;
        if (code === 200) {
          this.$message.success(`已加入选题库`);
          this.$emit('success');
          this.onClose();
        } else {
          return this.$message.error(`${message}`);
        }
      } catch (error) {
        this.saveLoading = false;
        console.log(error);
      }
    },
    /**
     * 修改选题
     */
    async updateTopic() {
      // if (!this.detailForm.topicSelectionType) return this.$message.warning('请选择类型');
      try {
        this.saveLoading = true;
        const { code, message } = await api.updateTopic({
          topicSelectionTagIdList: this.topicSelectionTagIdList,
          xhsTopicSelectionIdList: this.dataList,
        });
        this.saveLoading = false;
        if (code === 200) {
          this.$message.success(`修改成功`);
          this.$emit('success');
          this.onClose();
        } else {
          return this.$message.error(`${message}`);
        }
      } catch (error) {
        this.saveLoading = false;
        console.log(error);
      }
    },

    //修改文案模板选题
    async updateCopywritingTemplate() {
      try {
        const data = {
          ...this.dataList,
          topicTagList: this.topicSelectionTagIdList,
        };
        this.saveLoading = true;
        const { code, message } = await api.updateCopywritingTemplate(data);
        this.saveLoading = false;
        if (code === 200) {
          this.$message.success(`修改成功`);
          this.$emit('success');
          this.onClose();
        } else {
          return this.$message.error(`${message}`);
        }
      } catch (error) {
        this.saveLoading = false;
        console.log(error);
      }
    },
    /**
     * 统一保存方法
     */
    save() {
      if (this.topicSelectionTagIdList.length === 0) {
        this.$message.warning('请选择选题标签');
        return;
      }
      if (this.type === '修改') {
        this.updateTopic();
      } else if (this.type === '批量修改') {
        this.handleBatchEdit();
      } else if (this.type === '笔记') {
        this.pushNote();
      } else if (this.type === '笔记分析') {
        // 笔记分析
        this.pushNoteAnalyze();
      } else if (this.type === '文案模板') {
        this.updateCopywritingTemplate();
      }
      // else if (this.type === '图片') {
      //   this.pushImg();
      // }
    },

    // 选题库统一批量修改选题
    async handleBatchEdit() {
      if (!this.topicSelectionTagIdList.length) return this.$message.info('请选择选题');
      try {
        const params = {
          topicSelectionTagIdList: this.topicSelectionTagIdList,
          xhsTopicSelectionIdList: this.dataList,
          topicSelectionType: this.detailForm.topicSelectionType,
        };
        this.saveLoading = true;
        const { code, message } = await api.updateTopic(params);
        this.saveLoading = false;
        if (code === 200) {
          this.$message.success(`修改成功`);
          this.$emit('success');
          this.onClose();
        } else {
          return this.$message.error(`${message}`);
        }
      } catch (error) {
        this.saveLoading = false;
        console.log(error);
      }
    },
  },
  created() {},
};
</script>

<style scoped lang="scss"></style>
